/*!
 * Breadcrumbs ::: for JoshuaProject - imported by '../main.less' stylesheet ::: @chuckienorton @180bydesign
 */

// Importing Variables
@import '../variables.less';


.breadcrumbs {
	
	// Don't show on Home Page or xs size - removed xs size 10/20/15 possibly include
	body.index & {
		display: none;
	}
	@media (max-width: @screen-xs) {
		// display: none;
	}
  	.expand {
		.ex1 {
			display: none !important;
		}
		.ex2 {
			display: inline !important ;
		}		
		@media (max-width: @screen-md) {			
			.ex1 {
				display: inline !important;
			}
			.ex2 {
				display: none !important ;
			}
		}
	}
	.expand:hover {
		.ex1 {
			display: none !important ;
		}
		.ex2 {
			display: inline !important;
		}
		@media (max-width: @screen-md) {			
			.ex1 {
				display: none !important ;
			}
			.ex2 {
				display: inline !important;
			}
		}
	}

	.block {
	  position: absolute;
	  top: -50px;
	}
	
	background-color: @gray-lighter;
	
	.container {
		position: relative;
		padding: 18px 0px 12px 0px;	
	}
	
	.br_links {		
		select optgroup {
			color: @link-color;
			padding-left: 2px;
			background: url('/assets/img/jp_bg_1.gif') repeat;
		}
		select option {
			color: @black1;
			padding-left: 12px;
			background-color: @white1;
			@media (min-width: @screen-xs) {
				padding-top: 0px;
			}				
		}
		font-size:95%;
		margin-left: 10px;
		padding-top: 12px;
		a.crumb {
			cursor:default;
			text-decoration: none;
			float:left; 
			display:block;
			&:not(:first-child) {
				&:before {
					content:'\002F';
					padding: 0 8px;
					/*
					@media (max-width: @screen-xs) {
						content: '';
						padding: 0;
					}
					*/			
				}
			}
			&[href] {// only for the LINKS. 
				cursor:pointer;
				&:hover {
					font-weight:normal;
				}
			}
		}

		ul.breadcrumb_selector {
			margin: -2px 0 0 0;

			&.level_one {

				display:block;
				float:left;
				padding: 0 0px 0 7px;
				@media (max-width: @screen-xs) {
					padding-left: 4px; 
				}			

				// &:before {
				// 	float:left;
				// 	content:'\002F';
				// 	padding: 0 1%;
				// 	display:inline-block;
				// 	font-size:300%;
				// 	color:@offwhite2;
				// 	position: relative;
				// 	top:-16px;
				// 	margin: 0 1%;
				// }

				a {
					color:@gray-dark;
					cursor:pointer;
					text-decoration:none;
					display:inline-block;
					&:after {
						font-family: 'jp_icons';
						speak: none;
						font-style: normal;
						font-weight: normal;
						font-variant: normal;
						text-transform: none;
						line-height: 1;
						-webkit-font-smoothing: antialiased;
						content: "\e00d";
						color: @orange1;
						font-size:70%;
						padding: 0px 0px 0px 5px;
					}
				}
				li {
					padding-top:1px;
					padding-bottom:0px;
				}
				select {
					margin-bottom: 2px;
				}
				&:hover li .level_two {
					display:block;
				}

				.level_two {

					margin:0;
					left:inherit;
					right:-50;
					@media (min-width: @screen-sm) {
						min-width: 250px;
					}
				
					a {
						display:block;
						
						&:hover {
							color:@white1;
						}
						&:after {
							display:none;
						}
					}
				}
			}	
		}
	}
}

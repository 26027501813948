// Global Stats Tables

// Imporing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.global_stats {

	.observations {
		font-size: 125%;
		@media (max-width: @screen-xs) {
			font-size: 100%;
		}	
		font-weight: bold;
		padding-bottom: 15px;
	}	

	.lists_link {
		font-size: 120%;
		margin: 5% 0 5% 8%;
		display: block;
		@media (max-width: @screen-xs) {
			font-size: 100.1%;
		}		
	}

	.tabs {
		/* border-right:  solid @blue2; */
		/* border-width: 3px; */
		margin-right: auto;
		margin-left: auto;
		width: 50%;
		@media (max-width: @screen-lg) {
			width: 80%;
			border-left: none;
		}			
		@media (max-width: @screen-md) {
			/* margin-right: 10%; */
			border-width: 0px;
			border-left: 0;	
		}
		@media (max-width: @screen-sm) {
			/* margin-right: auto; */
			margin-left: auto;
			width: 100%;
		}
	}

	.nav-tabs {
		margin-right: auto;
		margin-left: auto;
		width: 100%;
		border-color: @blue2;
		border-width: 2px;
		.active a {
			margin-left: 0px;
			border-color: @blue2;
		}				
		a {
			font-weight: 700;
			font-size: 105%;
			color: @blue2;
			margin-right: 4px;
			margin-left: 0px;
			margin-bottom: -1px;
			padding: 7px 13px;
			text-wrap: yes;
			@media (min-width: @screen-lg) {
				font-size: 120%;
			}
			@media (max-width: @screen-md) {
				padding: 7px 11px;
			}
			@media (max-width: @screen-sm) {
				padding: 4px 7px;
				font-size: 100%;
			}
			@media (max-width: @screen-xs) {
				padding: 5px 5px 5px 5px;
				font-size: 100%;
			}
			p {
				@media (max-width: @screen-sm) {
					font-size: 55%;
				}	
			}
			.caret {
			  border-top-color: @white1;
			  border-bottom-color: @white1;
			}
		}
		a:hover {
			background-color: @blue2;
			opacity: 1;
			color: @white1;
			border-color: @blue2;
			p {
				color:@white1;
			}
			.caret {
				border-top-color: @white1;
				border-bottom-color: @white1;
			}
		}
		a:focus {
			border-color: @blue2;
			background-color: @blue2;
			color: @white1;
			.caret {
				border-top-color: @white1;
				border-bottom-color: @white1;
			}
		}
		.dropdown-menu-color {
			color: @blue2;
			background-color: @white1;
		}
	}
	
	.nav-tabs > .active > a, .nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus {
		color: @white1;
		background-color: @blue2 !important; 
		border-color: @blue2;
		outline: none;
		p {
			color: @white1;
		}
	}		
				
	table {
		border-collapse: initial;
		@media (max-width: @screen-xs) {
			width: 100%;
			td, th {
				font-size: 90%;
				padding-right: 0px !important;
			}
		}
	}

	.table_centered {
		margin-left: 0%;
		@media screen and (max-width: @screen-lg) {
			margin-left: 0%;
		}
		@media screen and (max-width: @screen-md) { 
			margin-right:auto;
			margin-left: auto;
		}
		@media screen and (max-width: @screen-xs) { 
			margin-left: 0;
			margin-right: 0;
			width: 100%
		}	
		max-height: 600px;
		overflow: scroll;
		overflow-x: hidden;		
	}

	.table-striped tbody > tr:nth-child(odd) > td, .table-striped tbody > tr:nth-child(odd) > th {
	   background-color: @gray-lighter;
	}		


	.td_text {
		display: block;
		margin-left: 4%;
		color: @gray-darker;
		font-weight: 700;
		@media (max-width: @screen-xs) {
			font-size: 80%;
			margin-left: 0;
		}
	}

	.td_sub_text {
		font-size: 90%;
		font-style: normal;
		display: block;
		line-height: 120%;
		margin: 0 0 0 7%;				
		@media (max-width: @screen-md) {
			margin: 0 0 0 2.5%;
			font-size: 87%;					
		}
		@media (max-width: @screen-xs) {
			margin: 0 0 0 0%;
			font-size: 80%;					
		}
	}

	.td_heading {
		color: @white1;
		font-weight: 600;
		font-size: 120%;
		line-height: 50%;
		margin: 0 0 0 0;
		@media (max-width: @screen-xs) {
			font-size: 95%;
			line-height: 15%;
		}
		a {
			color: @white1;
		}
	}
	.tr_background {
		border-top: 0px;
		border-style:solid;
		background-color: @gray;
		border-color: @gray;

	}

	.btn-group {
		width: 75%;
		margin-right: auto;
		margin-left: 22%;
		padding-bottom: 6%;									
		@media (max-width: @screen-lg) {
			margin-left: 31%
		}
		@media (max-width: @screen-md) {
			margin-left: 27.5%
		}
		@media (max-width: @screen-sm) {
			width: 58%;
			margin-left: 25%;
		}
		@media (max-width: @screen-xs) {
			width: 68%;
			margin-left: 19%;
		}
	}					

	.pie_chart {
		text-align: center;
		width: 100%;
		padding: 5% 0 2% 0;

		.chartwrapper {
			position: relative;
			margin: 0 auto;
			width: 460px;
			height: 320px;
			@media (max-width: @screen-xs) {
				margin: 0 auto;
				width: 200px;
				height: 200px;
			}
			background: #fff;
			/*border: 1px dotted #c00;*/
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}
	
		.chartdiv {
			position: absolute;
			top: -35px;
			left: -20px;
			width: 500px;
			height: 325px;
			@media (max-width: @screen-xs) {
				top: -30px;
				left: -45px;
				width: 280px;
				height: 260px;
			}	
			.click_listing {
				margin-top: 4px;
			}		
			/*border: 1px dotted #0c0;*/
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}		

		.title {
			font-size: 150%;
			font-weight: bold;
			@media (max-width: @screen-xs) {
				font-size: 125%;
			}
		}		
		.sub_title {
			font-size: 115%;
			font-weight: bold;
			margin: 0% auto 4%;
			@media (max-width: @screen-md) {
				font-size: 90%;
				margin-left: 0%;
			}
		}						
	}
} 

// End Global Stats 
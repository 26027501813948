//
// Glyphicons
//
// Since icons are fonts, they can be placed anywhere text is placed and are
// thus automatically sized to match the surrounding child. To use, create an
// inline element with the appropriate classes, like so:
//
// <a href="#"><i class="social social-leaf"></i> Leaf</a>

// Import the fonts
@font-face {
  font-family: 'Glyphicons Social';
  src: url('/assets/fonts/glyphicons-social-regular.eot');
  src: url('/assets/fonts/glyphicons-social-regular.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/glyphicons-social-regular.woff2') format('woff2'),
       url('/assets/fonts/glyphicons-social-regular.woff') format('woff'),
       url('/assets/fonts/glyphicons-social-regular.ttf') format('truetype'),
       url('/assets/fonts/glyphicons-social-regular.svg#glyphicons_socialregular') format('svg');
}

// Catchall baseclass
.social {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Social';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align:top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.social.x05{
  font-size:12px;
}
.social.x2{
  font-size:48px;
}
.social.x3{
  font-size:72px;
}
.social.x4{
  font-size:96px;
}
.social.x5{
  font-size:120px;
}


// Light
.social.light{
  &:before{
    color:#f2f2f2
  }
}

// Drop shadow
.social.drop{
  &:before{
    text-shadow: -1px 1px 3px rgba(0,0,0,0.3);
  }
}

// Horizontal Flip
.social.flip{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.social.flipv{
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

// Rotate
.social.rotate90{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.social.rotate180{
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.social.rotate270{
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}




// Individual icons
.social-pinterest{ &:before{ content:"\E001";} }
.social-dropbox{ &:before{ content:"\E002";} }
.social-google-plus{ &:before{ content:"\E003";} }
.social-jolicloud{ &:before{ content:"\E004";} }
.social-yahoo{ &:before{ content:"\E005";} }
.social-blogger{ &:before{ content:"\E006";} }
.social-picasa{ &:before{ content:"\E007";} }
.social-amazon{ &:before{ content:"\E008";} }
.social-tumblr{ &:before{ content:"\E009";} }
.social-wordpress{ &:before{ content:"\E010";} }
.social-instapaper{ &:before{ content:"\E011";} }
.social-evernote{ &:before{ content:"\E012";} }
.social-xing{ &:before{ content:"\E013";} }
.social-zootool{ &:before{ content:"\E014";} }
.social-dribbble{ &:before{ content:"\E015";} }
.social-deviantart{ &:before{ content:"\E016";} }
.social-read-it-later{ &:before{ content:"\E017";} }
.social-linked-in{ &:before{ content:"\E018";} }
.social-forrst{ &:before{ content:"\E019";} }
.social-pinboard{ &:before{ content:"\E020";} }
.social-behance{ &:before{ content:"\E021";} }
.social-github{ &:before{ content:"\E022";} }
.social-youtube{ &:before{ content:"\E023";} }
.social-skitch{ &:before{ content:"\E024";} }
.social-foursquare{ &:before{ content:"\E025";} }
.social-quora{ &:before{ content:"\E026";} }
.social-badoo{ &:before{ content:"\E027";} }
.social-spotify{ &:before{ content:"\E028";} }
.social-stumbleupon{ &:before{ content:"\E029";} }
.social-readability{ &:before{ content:"\E030";} }
.social-facebook{ &:before{ content:"\E031";} }
.social-twitter{ &:before{ content:"\E032";} }
.social-instagram{ &:before{ content:"\E033";} }
.social-posterous-spaces{ &:before{ content:"\E034";} }
.social-vimeo{ &:before{ content:"\E035";} }
.social-flickr{ &:before{ content:"\E036";} }
.social-last-fm{ &:before{ content:"\E037";} }
.social-rss{ &:before{ content:"\E038";} }
.social-skype{ &:before{ content:"\E039";} }
.social-e-mail{ &:before{ content:"\E040";} }
.social-vine{ &:before{ content:"\E041";} }
.social-myspace{ &:before{ content:"\E042";} }
.social-goodreads{ &:before{ content:"\E043";} }
.social-apple{ &:before{ content:"\F8FF";} }
.social-windows{ &:before{ content:"\E045";} }
.social-yelp{ &:before{ content:"\E046";} }
.social-playstation{ &:before{ content:"\E047";} }
.social-xbox{ &:before{ content:"\E048";} }
.social-android{ &:before{ content:"\E049";} }
.social-ios{ &:before{ content:"\E050";} }
.social-wikipedia{ &:before{ content:"\E051";} }
.social-pocket{ &:before{ content:"\E052";} }
.social-steam{ &:before{ content:"\E053";} }
.social-souncloud{ &:before{ content:"\E054";} }
.social-slideshare{ &:before{ content:"\E055";} }
.social-netflix{ &:before{ content:"\E056";} }
.social-paypal{ &:before{ content:"\E057";} }
.social-google-drive{ &:before{ content:"\E058";} }
.social-linux-foundation{ &:before{ content:"\E059";} }
.social-ebay{ &:before{ content:"\E060";} }


/*
*
* THIS IS A SMALL BONUS FOR ALL CURIOUS PEOPLE :)
* Just add class .animated and .pulse, .rotateIn, .bounce, .swing or .tada to you HTML element with icons. You may find other great css animations here: http://coveloping.com/tools/css-animation-generator
*
*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
}

@-webkit-keyframes pulse {
  0% { -webkit-transform: scale(1); }
  50% { -webkit-transform: scale(1.1); }
  100% { -webkit-transform: scale(1); }
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rotateIn {
  0% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(-200deg);
      opacity: 0;
  }
  100% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
      transform-origin: center center;
      transform: rotate(-200deg);
      opacity: 0;
  }
  100% {
      transform-origin: center center;
      transform: rotate(0);
      opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% { -webkit-transform-origin: top center; }
  20% { -webkit-transform: rotate(15deg); }
  40% { -webkit-transform: rotate(-10deg); }
  60% { -webkit-transform: rotate(5deg); }
  80% { -webkit-transform: rotate(-5deg); }
  100% { -webkit-transform: rotate(0deg); }
}
@keyframes swing {
  20% { transform: rotate(15deg); }
  40% { transform: rotate(-10deg); }
  60% { transform: rotate(5deg); }
  80% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  0% {-webkit-transform: scale(1);}
  10%, 20% {-webkit-transform: scale(0.9) rotate(-3deg);}
  30%, 50%, 70%, 90% {-webkit-transform: scale(1.1) rotate(3deg);}
  40%, 60%, 80% {-webkit-transform: scale(1.1) rotate(-3deg);}
  100% {-webkit-transform: scale(1) rotate(0);}
}
@keyframes tada {
  0% {transform: scale(1);}
  10%, 20% {transform: scale(0.9) rotate(-3deg);}
  30%, 50%, 70%, 90% {transform: scale(1.1) rotate(3deg);}
  40%, 60%, 80% {transform: scale(1.1) rotate(-3deg);}
  100% {transform: scale(1) rotate(0);}
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

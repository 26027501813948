// Imporing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";
/**
 * WHERE TO FIND MEDIA TABLE:
 * https://github.com/thepeg/MediaTable
 * http://consulenza-web.com/jquery/MediaTable/
 * http://www.consulenza-web.com/2012/01/mediatable-jquery-plugin/
 */

/* -----[[     S T R U C T U R E     ]]------------ */

/**
 * Structural Style
 * This is styles used by Javascript, so do not strip it.
 */
/* purgecss start ignore */
.mediaTable {
	border-collapse:collapse;
	width:100%;
}

.mediaTableWrapper {
	position: relative;
}

.mediaTableWrapperWithMenu {
	margin: 0px 0px 15px 0px;	/* Changed 07/27/20, was 0px 15px 15px 15px; */
	@media (max-width: @screen-md) {
		/* margin: 0px 15px 15px 15px; */
	}
	@media (max-width: @screen-xs) {
		/* margin: 0 5px 15px 5px; */
	}
}

.mediaTableMenuClosed ul {
	display:none;
}

/**
 * Active Media Table Rules
 * these rules are applied to a table activated by the jQuery plugin.
 */

.activeMediaTable th, .activeMediaTable td {
	display: none;
}

.activeMediaTable th.essential, .activeMediaTable td.essential {
	display: table-cell;
	/* display:block; IE6 Hack */

}

/* IE7 Hack */
*+html .activeMediaTable th.essential, *+html .activeMediaTable td.essential {
	display:block
}

input {
    border-radius: 0;
}






/* -----[[     B R E A K      P O I N T S     ]]------------ */

@media screen and (min-width: 768px) {

	.activeMediaTable th.optional, .activeMediaTable td.optional {
		display: table-cell;
		/* _display:block;  IE6 Hack */

	}

	/* IE7 Hack */
	*+html .activeMediaTable th.optional, *+html .activeMediaTable td.optional { display:block }

}

@media screen and (min-width: 1024px) {

	.activeMediaTable th, .activeMediaTable td {
		display: table-cell;
		/* _display:block;  IE6 Hack */
	}

	/* IE7 Hack */
	*+html .activeMediaTable th, *+html .activeMediaTable td { display:block }

}



/* -----[[     T H E M I N G     ]]------------ */

/** Table Style **/

table.mediaTable thead th {
	/* white-space: nowrap; */
	/* border-bottom: 1px solid #ccc; */
	/* color: #888; */
}
table.mediaTable tr {
	max-height: 30px !important;
	> hover {
		background-color: @table-bg-hover !important;
	}
}

table.mediaTable th, table.mediaTable td {
	font-size: 90%;  /* 98% */
	@media (max-width: @screen-xs) {
		font-size: 85%;
	}
	margin: 0;
	text-align: right;
}

table.mediaTable th {
	padding: 7px 4px 7px 4px !important;
	a, a:hover, a:active, a:visited {
		color: @black;
		text-decoration: none;
	}
}
table.mediaTable td {
	padding: 5px 6px 5px 0px !important;
	vertical-align: middle !important;
}

table.mediaTable th:first-child, table.mediaTable td:first-child {
	text-align: left;
	padding-left: 5px !important;
}
table.mediaTable tfoot tr td {
	padding: 4px 6px 4px 5px !important;
}
.hidden {
	display: none;
}
.no_wrap {
	white-space: nowrap;
	overflow: hidden;
}

.list_sub_header {
	font-size: 80%;
}
/** Menu Style **/

.mediaTableMenu {
	position: absolute;
	top: 0;
	@media (max-width: @screen-xs) {
		top: 5px;
	}
	right: 0;
	text-align: left;
	background: #fff;
	border:1px solid #aaa;
	border-radius:5px;
	overflow:hidden;
	box-shadow:2px 2px 4px #ddd;
	z-index:9;
}

.mediaTableMenu a {
	display: block;
	background: @gray-lighter;
	padding:4px 5px;
	cursor:pointer;
	font-family: inherit;
	font-size: 90%;
	-webkit-border-top-right-radius: 5px;
	-webkit-border-top-left-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-topleft: 5px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;

}

.mediaTableMenu input[type="checkbox"] {
	margin: 0;
	vertical-align: middle;
    -webkit-appearance: checkbox;
}


.mediaTableMenu ul {
	list-style-position:outside;
	list-style:none;
	margin:0;
	padding:0 0.5em;
}

.mediaTableMenuClosed a {
	border-radius:5px;
}

.mediaTableMenu ul li {
	line-height: 1.5em;
}

.mediaTableMenu ul li label {
	cursor:pointer;
	font-family: inherit;
	font-size: 90%;
	font-weight: bold;
}
/* purgecss end ignore */

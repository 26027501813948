// Imported Fonts
// ------------------------

/* Open Sans */
@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Regular-webfont.eot');
    src: url('/assets/fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('/assets/fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Italic-webfont.eot');
    src: url('/assets/fonts/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/OpenSans-Italic-webfont.woff') format('woff'),
         url('/assets/fonts/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('/assets/fonts/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Semibold-webfont.eot');
    src: url('/assets/fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/OpenSans-Semibold-webfont.woff') format('woff'),
         url('/assets/fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('/assets/fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: 700;
    font-style: normal;
}
/* Chivo */
@font-face {
    font-family: 'Chivo';
    src: url('/assets/fonts/Chivo-Regular-webfont.eot');
    src: url('/assets/fonts/Chivo-Regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Chivo-Regular-webfont.woff') format('woff'),
        url('/assets/fonts/Chivo-Regular-webfont.ttf') format('truetype'),
        url('/assets/fonts/Chivo-Regular-webfont.svg#ChivoRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Chivo';
    src: url('/assets/fonts/Chivo-Black-webfont.eot');
    src: url('/assets/fonts/Chivo-Black-webfont.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Chivo-Black-webfont.woff') format('woff'),
        url('/assets/fonts/Chivo-Black-webfont.ttf') format('truetype'),
        url('/assets/fonts/Chivo-Black-webfont.svg#ChivoBlack') format('svg');
    font-weight: bold;
    font-style: normal;
}

.font_os {
  font-family: 'Open Sans', sans-serif;
}
.font_ab {
  font-family: 'Chivo', sans-serif;
}

// Imporing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.help {

	h2 {
		color: @red2;
		font-size: 215%;
		font-weight: 400;
		margin-top: 25px;
	}
	h3 {
		color: black;
		font-size: 150%;
		font-weight: 400;
		margin: 25px 0 2px 0;
		padding: 0;
	}
	h4 {
		color: @red2;
		font-size: 130%;
		font-weight: 400;
		margin: 10px 0 0 0;
	}
	p {
		font-size: 115%;
		margin: 0 0 15px 0;
		padding: 0;
		line-height: 180%;
	}
	.red-bold {
		color: @red2;
		font-weight: 900;
	}
	ul, ol {
		font-size: 115%;
		line-height: 180%;
		padding-left: 7%;		
	}
	ul {
		list-style-type: disc;
	}
	ol {
		list-style-type: decimal;
		li {
			padding-left: 5px;
		}
	}
}
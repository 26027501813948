/*!
 * People Group Styling ::: for JoshuaProject - imported by '../main.less' stylesheet ::: @chuckienorton @180bydesign @degrovejazz
 */

// Importing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.people_groups {
	h2 {
		/* margin-left: 0 !important; commented out 3/16/23 */
		margin-left: 5px !important;
	}
	// defaults

	.listing_wide {
		margin-right: 12%;
		margin-left: 12%;
		@media (max-width: @screen-lg) {
			margin-right: 0%;
			margin-left: 5%;
		}
		@media (max-width: @screen-md) {
			margin-right: 0%;
			margin-left: 3%;
		}
		@media (max-width: @screen-sm) {
			margin-right: 0%;
			margin-left: 0%;
		}
	}

	.expand_close_all {
		background-color: transparent;
		color: @link-color;
		font-size: 95%;
		margin: 1% 0 0 0;
		border: none;
		box-shadow: none;
	  .glyphicon {
			vertical-align: middle;
			display:inline block;
			color:@link-color !important;
			font-size:105% !important;
			transform:rotate(45deg);
		}
	}

	.nobold {
		font-weight: normal;
	}

	.data_download_button {
		background-color: transparent;
		color:@link-color;
		font-size:95%;
		margin: 1% 0 0 0;
		border: none;
		box-shadow: none;
		.glyphicon {
			display:inline;
			color:@link-color;
			font-size:135%;
		}
	}

	.minor-peoples {
		font-size: 95%;
		line-height: 150%;
		h4 {
			margin-top: 0;
			text-align: center;
		}
		@media (min-width:@screen-md) {
			width: 700px;
		}
		.center {
			text-align: center;
		}
	}

	.southasia-adherents-note {
		text-align: center;
		font-size: 90%;
		margin: 10px auto 0;
		width: 95%;
		display: inline-block;
		@media (max-width:@screen-xs) {
			font-size: 80%;
			width: 100%;
		}
	}

	.minor-peoples-link {
		font-size: 95%;
		line-height: 160%;
		margin: 0 4% 10px 0;
		padding: 0;
		font-weight: normal;
		a {
			@media (max-width:@screen-xs) {
				font-size: 90%;
			}
		}
		@media (min-width:@screen-md) {
			/* margin-left: 4.5%; */
			margin-left: 50px;
		}
	}
	.minor-countries-link {
		font-size: 95%;
		margin: 0 0 10px 0;
		padding: 0;
		font-weight: normal;
		a {
			@media (max-width:@screen-xs) {
				font-size: 90%;
			}
		}
	}
	.minor-peoples-table {
		max-height: 510px;
		td {
			background-color: transparent !important;
		}
		@media (max-width:@screen-md) {
			max-height: 400px;
		}
		@media (max-width:@screen-sm) {
			max-height: 350px;
		}
		@media (max-width:@screen-xs) {
			max-height: 300px;
		}
		overflow-y: scroll;
	}

	.minor-hide {
		@media (max-width:@screen-xs) {
			display: none;
		}
	}
	.subgroups-table {
		max-width: 400px;
		font-size: 115%;
		margin: 10px 0 !important;
		td:last-child, th:last-child {
			padding-right: 15px !important;
		}
	}
	.symbols-table {
		max-width: 385px;
		font-size: 110%;
		@media (max-width:@screen-xs) {
			font-size: 110%;
		}
		margin: -10px 0 0 0;
		overflow: hidden;
		.table {
			box-shadow: none !important;
			font-size: 100%;
			width: 100%;
			margin: 10px 0 !important;
		}
		td, th {
			white-space: nowrap;
			text-align: left;
			font-size: 87%;
		}
		td:first-child, th:first-child {
			padding: 2px !important;
			text-align: center;
			vertical-align: middle;
		}
		img {
			max-width: 30px !important;
		}
		span {
			font-size: 85%;
		}
	}
	.map-symbols { 
		line-height: 150%;
		img {
			max-width: 16px;
			padding: 0 2px;
		}
		.x-button {
			max-width: 100% !important;
		}
		padding-bottom: 8px;
	}
	.map-lines {
		td, th {
			padding: 2px;
			text-align: left !important;
		}
	}
	.language-links	{
		font-size: large;
	}
	.filter {
		font-size: 100%;
		text-align: center;
		margin-top: 10px;
		.results {
			text-align: center;
			margin: 25px 0 0 0;
			@media (max-width:@screen-xs) {
				padding: 0;
				margin: 0 -10px !important;
			}
			.results-table {
				padding: 0 0 10px 0;
				max-width: 65%;
				background-color: @gray-lighter;
				border-radius: 5px;
				font-size: 120%;
				@media (max-width:@screen-sm) {
					width: 70%;
					margin: 0;
					font-size: 95%;
				}
				@media (max-width:@screen-xs) {
					width: 100%;
					font-size: 90%;
				}
				td, th {
					text-align: right;
					white-space: nowrap;
					@media (max-width:@screen-xs) {
							font-size: 110%;
							padding: 5px 8px;
						}
					.result {
						color: @black1;
						@media (max-width:@screen-sm) {
							font-size: 110%;
						}
					}
				}
				th {
					padding: 5px 5px 5px 10px;
					font-weight: bold;
					color: @red2;
				}
				td {
					padding: 0px 5px 10px 10px;
					color: @black1;
				}
				td:first-child {
					padding-left: 15px;
				}
				td:last-child {
					padding-right: 15px;
				}
				.pct {
					font-style: italic;
					font-size: 90%;
					vertical-align: middle;
				}
			}
		}
		.option {
			color: @gray-dark;
		}

		/* purgecss start ignore */
		
		.remove-buttons {
			text-align: center;
			.btn {
				margin: 3px;
				padding: 1px 5px 1px 5px;
				font-size: 95%;
				border:none;
				background-color: @red2;
			}
		}


		label {
			margin-top: 10px;
			margin-bottom: 0px;
		}
		input {
			/* width: 4.5em; */
			display: inline;
		}
		hr {
			padding: 0;
			margin: 15px auto 5px;
			height: 3px;
			background-color: @blue2;
			max-width: 170px;
		}
		.selectlist {
			width: 170px;
		}
		.selectpop {
			width: 8em;
		}
		.selectspinner {
			width: 4em;
		}
		#pglist {
			margin: 0 auto;
			font-size: 105%;
			th {
				border-bottom: 1px solid #000 !important;
				cursor: pointer;
			}
			td {
				border-bottom: 1px solid #DDD;
			}
			th, td {
				max-width: 150px !important;
			}
		}
		.sortnow {
			cursor: pointer;
		}

		.filter-table {
			max-height: 700px !important;
			overflow: scroll-y;
		}
	}

	.list_start_page {
		text-align: center;
		line-height: 185%;
		font-size: 110%;
		@media (max-width: @screen-sm) {
			font-size: 99%;
		}
		p {
			margin: 5px 0 10px 0;
			text-align: left;
			font-size: 105%;
		}
		a {
			/* font-size: 105%; removed 07/10/21 */
		}
		hr {
			margin: 7px 0;
		}
		h3 {
			margin-bottom: 15px;
		}
		h5 {
			background: #BBDDFF;
			margin-bottom: 5px;
			padding: 4px;
			a, a:visited {
				color: @gray-darker !important;
			}
		}
		.middle {
			vertical-align: middle;
		}
		.sub_title {
			font-size: 80%;
		}
		ol {
			list-style-type: decimal;
			font-size: 105%;
			padding-left: 20%;
			@media (min-width: @screen-lg) {
				padding-left: 22%;
			}
			margin: 0 0 10px 0 !important;
			@media (max-width: @screen-xs) {
				font-size: 90%;
				padding: 0 0 0 15px;
			}
		}
		ul {
			list-style-type:disc;
			padding-left: 5%;
			font-size: 105%;
			margin: 0 0 10px 10%;
			@media (max-width: @screen-xs) {
				margin: 0 0 10px 0 !important;
				font-size: 100%;
			}
			@media (min-width: @screen-sm) and (max-width: @screen-md) {
				margin: 0 0 10px 20% !important;
			}
			@media (min-width: @screen-lg) {
				margin: 0 0 10px 10% !important;
			}
		}
		li {
			text-align: left;
			padding-left: 10px;
			line-height: 175%;
			@media (max-width: @screen-xs) {
				padding-left: 2px;
			}
		}
		hr {
			max-width: 50%;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.center {
		text-align: center;
	}

	.center_button {
		margin-right: auto;
		margin-left: auto;
		padding-top: 1%;
		width: 20%;
	}

	.notes {
		p {
			margin: 0 0 15px 0;
			font-size: 100%;
			line-height: 160%;
		}
	}

	.sub_title {
		p {
			color: @blue2;
			font-size: 70%;
			font-style: normal;
			line-height: 120%;
			margin-right: 10px;
			margin-left: 15px;
			@media (max-width: @screen-lg) {
				margin: 0 0 0 2px;
				font-size: 70%;
			}
			@media (max-width: @screen-md) {
				font-size: 70%;
				margin: 0 0 0 0px;
			}
			@media (max-width: @screen-sm) {
				margin: 0 0 0 2px;
				font-size: 100%;
			}
		}
	}

	.pg_name_listings {
		display: inline-block;
		margin-top: 0;
		padding-top: 0;
	}

	.aff_heading {
		p {
			margin: 8% 10% 1% 50px;
			text-align: center;
			color:@green1;
			font-size: 125%;
			font-style: italic;
			display: block;
			line-height: 120%;
			vertical-align: top;

				@media (max-width: @screen-lg) {
					font-size: 105%;
					margin: 8% 0% 1% 15%;


					}
				@media (max-width: @screen-md) {
					font-size: 100%;
					margin-top: 2%;
					margin-left: auto;
					margin-right: auto;
					width: 80%;
					}
				}
		}

	.aff_map {
		width: 90%;
		margin-left: 20%;
		margin-right: auto;
		@media (max-width:@screen-lg) {
				width: 120%;
				margin-left: .5%;
		}
		@media (max-width:@screen-md) {
				width: 100%;
		}
	}

	.aff_map_colors {
		margin-left: 3px;
		vertical-align: middle;
		width: 25px;
	}

	white_back { // for aff bloc page
		background: @white1;
		padding: 8px 0;
		margin: 0 0 7px 0;
	}

	.cluster_heading {
		text-align: center;
		p {
			color:@green1;
			font-size: 175%;
			font-style: bold;
			padding: 15px 0 0 0;
		}
		.sub_title {
			font-size: 100%;
			line-height: 90%;
			padding: 2px 0 0 0;
		}
		.white {
			color: white;
		}
	}
	.cluster-future-popl {
		margin: 0;
		padding: 5px 0 0 0;
		font-size: 75%;
		line-height: 150%;
		color: @gray-dark;
		text-align: center;
	}


/* Google Maps */

	.google_map {
		text-align: center;
		margin: 10px auto;
		width: 90%;
		height: 520px;
		max-width: 100%;
		box-shadow: 6px 6px 4px @gray-lighter;
		img, object, embed {
			max-width: none;
		}
		@media (max-width:@screen-lg) {
			width:100%;
			height:500px;
		}
		@media (max-width:@screen-md) {
			width:100%;
			height:345px;
		}
		@media (max-width:@screen-sm) {
			width:100%;
			height:275px;
		}
		@media (max-width:@screen-xs) {
			width:100%;
			height: 260px;
			margin-bottom: 15px;
		}
	}
	.google_map_ctry {
		margin: 0px auto 5px;
		width: 100%;
		height: 365px;
		text-align: center;
	}
	.google_map_people {
		margin: 0 auto 10px;
		width: 100%;
		height: 340px;
		text-align: center;
	}
	.google_map_state {
		margin: 0px auto 0;
		width: 118%;
		height: 365px;
		max-width: none;
		width: none;
		img, object, embed {
			max-width: none;
		}
		@media (max-width:@screen-lg) {
			width: 208%;
			height: 260px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 15px;
		}
		@media (max-width:@screen-md) {
			width:100%;
			height:300px;
		}
		@media (max-width:@screen-sm) {
			width:100%;
			height:275px;
		}
		@media (max-width:@screen-xs) {
			width: 100%;
			height: 260px;
		}
	}
	.google_map_state_wrap {
		padding-top: 10px;
		margin-right: auto;
		margin-left: auto;
		align: center;
	}
	.info_window {
		max-width: 200px;
		text-align: center;
		font-family: @font-family-sans-serif;
		font-weight: bold;
		// padding-left: 20px;
		// line-height: 1.75;
		img {
			/* max-width: 130px; */
			max-height: 160px;
			padding: 5px 0;
		}
		.dot {
			vertical-align: bottom;
			margin-bottom: 0px;
		}
	}

/* End Google Maps */

	.writing-guide {
		div {
			margin: 10px auto;
		}
	}
	.num_right {
		text-align: right;
		}
	.title_center {
		text-align: center;
		}

	.listing_note {
		margin: 1% 50% 0 3.7%;
		font-size: 90%;
		@media (max-width: @screen-lg) {
			margin-right: 0;
		}
		@media (max-width: @screen-xs) {
			font-size: 65%;
		}
	 }

	 .crumb { //for breadcrumb dropdown
		.dropdown-menu {
			width: 150%;
		}
		.level_two{
			width: 120%;
			a {
				line-height: 95%;
				word-wrap:break-word;
				white-space: normal;
			}
		}
	}

	.hide_td_sm { // used to hide fields on small screens
		@media (max-width:@screen-sm) {
			display: none !important;
		}
	}

	.hide_td_md { // used to hide fields on small screens
		@media (max-width:@screen-md) {
			display: none !important;
		}
	}

	.hide_td_lg { // used to hide fields on small screens
		@media (max-width:@screen-lg) {
			display: none !important;
		}
	}

	.hide_page_sm { // used to hide bottom pagination links on small screens
		@media (max-width:@screen-md) {
			display: none !important;
		}
	}

	.pg_well {
		background-color: @gray-lighter;
		border: 1px solid @gray-lighter;
		color: @black !important;
		font-size: 105%;
		padding: 7px 0;
		margin: 10px auto 20px;
		text-align: center;
		@media (max-width: @screen-xs) {
			font-size: 80%;
		}
	}

	.panel {
		padding-top: 5px;
		text-align: left;
		border-width: 0;
		background-color: transparent;
		box-shadow: none;
		border: none;
		.white {
			color: @white1;
		}
		.lang_columns {
			td:first-child {
				width: 25%;
			}
			@media (max-width: @screen-xs) {
				td:first-child {
					width: 37%;
				}
			}
		}
		.lang_columns_data {
			td {
				table {
				max-width: 60%;
					@media (min-width: @screen-lg) {
						max-width:40%;
					}
					td {
						border: none;
						padding: 0 10px 10px 0!important;
					}
					.data {
						text-align: right;
						max-width: 50px;
					}
				}
			}
		}
		.lang_columns_four {
			@media (max-width: @screen-lg) {
				display:none !important;
			}
			td {
				table {
					width: 100%;
					td:first-child, td {
						width: 33%;
						border: none;
						padding: 0 10px 10px 0!important;
					}
				}
			}
		}
		.lang_columns_two {
			@media (min-width: @screen-lg) {
				display: none !important;
			}
			@media (max-width: @screen-xs) {
				font-size: 90%;
			}
			td:first-child {
				width: 37%;
			}
		}
	}

	.panel-heading {
		background-color: transparent;
		box-shadow: none;
		font-family: "Chivo",sans-serif;
		font-size: 180%;
		@media (max-width: @screen-sm) {
			font-size: 150%;
		}
		@media (max-width: @screen-xs) {
			font-size: 125%;
		}
		line-height: 1;
		padding: 0;
		margin: 0px 13% 2% 0%;
		a, a:hover, a:focus {
			text-decoration: none !important;
		}
	}

	.panel-heading .accordion-toggle:after {
		/* symbol for "opening" panels */
		font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
		content: "\e113";    /* adjust as needed, taken from bootstrap.css */
		padding-left: 5px;;
		margin: 0;
		font-size:50%;
		display: inline;
	}

	.panel-heading .accordion-toggle.collapsed:after {
		/* symbol for "collapsed" panels */
		content: "\e114";    /* adjust as needed, taken from bootstrap.css */
	}
	.panel-body {
		font-size: 105%;
		line-height: 175%;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		@media (max-width: @screen-xs) {
			padding-left: 0px;
			padding-right: 0px;
			font-size: 90%;
		}

		.table-hover {
			margin-top: 0;
		}
		.update-btn {
			margin-top: -46px;
			@media (max-width: @screen-xs) {
				margin-top: -32px;
				font-size: 90%;
			}
		}
	}

	.embed-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
		height: auto;
	}
	.embed-container iframe, .embed-container object, .embed-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.video-container {
		position: relative;
	    padding-bottom: 56.25%;
	    padding-top: 0px;
		margin-bottom: 10px;
	    overflow: hidden;
	 	// width: 70%;
		// height: 85%;
		// @media (max-width: @screen-lg) {
		// 	width: 95%;
		//	height: 100%;
		// }
		// @media (max-width: @screen-xs) {
		//	width: 100%;
		//	height: 30%;
		// }
	}
	.video-container iframe, .video-container object, .video-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0% .5% 1% .5%;
	}
	.video-container-mp4 {
		position: relative;
	    padding-bottom: 10px !important;
	    padding-top: 0px;
	    overflow: hidden;
		width: 75%;
		height: 75%;
		@media (max-width: @screen-md) {
			width: 85%;
			height: 85%;
		}
		@media (max-width: @screen-sm) {
			width: 95%;
			height: 95%;
		}
		@media (max-width: @screen-xs) {
			width: 100%;
			height: 100%;
		}
		video {
			width: 100%    !important;
			height: auto   !important;
		}
	}
	.video-title-spacer {
		line-height: 8px;
	}

	#profile {
		.content {
			margin-left: 0;
			text-align: left;
		}
	}
	.profile-table {
		white-space: nowrap;
		td {
			padding: 3px 10px 3px 0px;
			font-size: inherit;
		}
		margin-bottom: 10px;
	}
	.profile-columns {
		width: 30%;
	}
	.resources-table {
		p {
			@media (min-width: @screen-xs) {
				font-size: 105%;
				padding-bottom: 10px;
			}
			@media (max-width: @screen-xs) {
				font-size: 90%;
				padding-bottom: 10px;
			}
		}
	}
	.religion-table {
		display: inline-block;
		width: 45%;
		@media (max-width: @screen-lg) {
			width: 100%;
		}
	}
	.column_table {
		width: 100px;
		margin: 0 0 0 0 !important;
	}

	.container .well { // for filter warning
				margin-top: 4px;
				margin-bottom: 8px;
				background-color: @gray-dark;
				color: @white1;
				font-size: 120%;
				padding: 2px 2px;
				margins-left: auto;
				margins-right: auto;
				text-align: center;
				@media (max-width: @screen-xs) {
					font-size: 80%;
					}
				}

	.not_filtered {
		.well { // for not filter not
			margin-top: 4px;
			margin-bottom: 8px;
			border-radious: 6px;
			background-color: @green1;
			color: @white1;
			font-size: 105%;
			font-style: italic;
			padding: 1px 0 1px 0;
			margins:  0 0 0 0;
			text-align: center;

		}
	}

	// Added next three styles for Johnathan's reworking of filter page

	a.btn-primary:visited {
		color: #ffffff;
	}
	input#share-link {
		width: 50%;
		@media (max-width: @screen-lg) {
			width: 65%;
		}
		@media (max-width: @screen-md) {
			width: 75%;
		}
		@media (max-width: @screen-sm) {
			width: 90%;
		}
		@media (max-width: @screen-xs) {
			width: 100%;
		}
	}

    #results_per_page {
      width: 65px;
    }

	.listing_filter {
			background-color: @white1;
			color: @gray-dark;
			font-size: 110%;
			font-weight: 600;
			margin-bottom: 10px ;
			text-align: center;
				@media (max-width: @screen-md) {
					font-size: 100%;
					}
				@media (max-width: @screen-xs) {
					font-size: 71%;
					padding: 0 0 d0 0;
					margin: 0 0 0 0;
					}
			.filter_heading_row {
				text-align: left;
				}

			.filter_heading {
				@media (max-width: @screen-lg) {
					font-size: 80%;
					}
				@media (max-width: @screen-md) {
					font-size: 100%;
					margin-left: 2%;
					}
				@media (max-width: @screen-xs) {
					font-size: 95%;
					}
					.sub {
						font-size: 60%;
					}
				select {
					font-size: 90%;
				}
			}


			a {
				color: @gray-dark;
				}

			.head {
				margin: 1% 0 1.5% 0;
				}
			.filter_head {
				color: @gray-dark;
				font-size: 110%;
				font-weight: 900;
				display: inline;
				float: right;
				}
				.filter_head:hover {
					font-style: italic;
					text-decoration: underline;
					}
			.filter_sec_bottom {
				@media (max-width: @screen-md) {
						padding-top: 3%;
					}
			}

			.clear_filters {
				color: @gray-dark;
				font-size: 90%;
				font-weight: 900;
				display: inline;
				margin: 0% 1% 2% 1;
				white-space: nowrap;
					@media (max-width: @screen-md) {
						padding: 10% 0 0 0;
						}
					@media (max-width: @screen-xs) {
						font-size: 105%;

						}
					}
				.clear_filters:hover {
					font-style: italic;
					text-decoration: underline;

					}
			.add_clear {
				@media (max-width: @screen-md) {
					margin-top: 8%;
					}
				@media (max-width: @screen-sm) {
					margin-top: 10%;
					margin-bottom: 2%;
					}

				}
			.add_filters {
				color: @green1;
				font-size: 90%;
				padding-right: 2%;
				font-weight: 900;
				display: inline;
				margin: 0% 1% 2% 1%;
				white-space: nowrap;
					@media (max-width: @screen-xs) {
						font-size: 105%;

						}
				}
			.add_filters:hover {
					font-style: italic;
					text-decoration: underline;
				}
			.more_filters {
				color: @green1;
				font-size: 95%;
				padding-left: 0;
				font-weight: 900;
				float: right;
				display: inline;
				margin: 0% 5% 0% 0%;
					@media (max-width: @screen-xs) {
						font-size: 105%;
						margin-top: 2%;
						}
				}


				.more_filters:hover {
					font-style: italic;
					text-decoration: underline;
					}
				#morefilters {
					margin-left: 2%;
				}
			.all {
				color: @green1;
				font-size: 138%;
				display: inline;

				}
			.all_link {
				font-size: 100%;
				}
			.all_link:hover {
				font-style: italic;
				}

			.filtered_top {
				color: @gray-dark;
				font-size: 138%;
				display: inline;

				}

			hr {
				margin: 0 0% 0 0%;
				}

			.btn-xs {
				width: 152px;
				height: 23px;
				font-size: 88%;
				background-color: @green1;
				border-color: @white1;
				border-width: 0;
				border-width: 0px;
				color: @white1;

					@media (max-width: @screen-lg) {
						width: 117px;
						padding: 0 0 0 0;
						font-size: 74%;
						}
					@media (max-width: @screen-md) {
						width: 120px;
						font-size: 73%;
						}
					@media (max-width: @screen-xs) {
						height: 20px;
						width: 91px;
						margin: 5px 0 5px 0;
						padding: 10px 2px 10px -10px;
						font-size: 82%;
						border-width: 3px;

						}
				}

			.btn {
				background-color: @green1;
				border-width: 0;
				border-width: 0px;
				margin: 1.3% 6% 2% 0 ;
				color: @white1;
				padding-top: 0px;
			}

			.btn:hover {
				opacity: 0.6;
				}
			.btn.active, .btn:active {
				  background-image: none;
				  @shadow: inset 0 0 0 , 0 0 0 ;
				  .box-shadow(@shadow);
				  background-color: @gray-light;
				  border-width: 0;
				  color: @white1;
				  font-style: italic;
				  font-size: 85%;
				  @media (max-width: @screen-xs) {
					  font-size: 76%;
				  }
			}
			.btn.active:hover {
				opacity: 0.6;
				}

			.limit_to {

				}

			.placehold {
				color: transparent;
				font-size: 150%;
				}
			.filter_only {
				font-size: 80%;
				color: @green1;
				background-color: @white1;
				border-width: 0;
				display: inline;
				}
			.filter_only:hover {
				font-style: italic;
				}

			.filter {
				background-color: @green1;
				display: inline;
				font-size: 103%;
				margin-right: 1%;
				width: 81px;
				float: right;
				padding-top: 4px;
				margin: -3% 6% 2% 0%;
					@media (max-width: @screen-md) {
							margin-top: 0%;
							margin-right: 45%;
							}
					@media (max-width: @screen-xs) {
							margin: 3% 2% 4% 0;
							}
				}

			.filter_directions {
				margin-bottom: 10px;
					.in {
						color: @green1;
						font-size: 110%;
						border-radious: 20px;
						}
					.out {
						color: @gray-light;
						font-size: 110%;
						}
					}

			.accordion-toggle {
					color: @green1;
					font-size: 90%;
				}
				.accordion-toggle:hover {
					font-style: italic;
					text-decoration: underline;
				}

		} // end listing filter

		.social-links {
			margin: 0 20px;
			ul {
				display: inline;
			}
			li {
				display: inline;
				cursor: pointer;
			}
			img {
				margin: -3px 2px 0 2px;
				max-width: 32px;
				max-height: 32px;
			}
			a {
				text-decoration: none !important;
				color: @gray-dark;
			}
		}

		.bodytext {
			font-size:115%;
			line-height: 170%;
			margin: 2% 30% 2% 2%;

			@media (max-width: @screen-lg) {
				margin: 4% 0 2% 0;
			}
			@media (max-width: @screen-md) {
				margin: 4% 0 2% 0;
			}
			@media (max-width: @screen-sm) {
				font-size:100%
			}
		}

		.ministry_listing {
			margin-top: 0;
			margin-bottom: 0;
			table {
				margin-top: 0;
			}
			ul {
				list-style-type:disc;
				margin: 1% 0 0 0;
				padding-left: 9%;
			}
			h7 {
				color: @black1;
				font-weight: 600;
			}
		}

		// Tables
		.table  tbody > tr > td {
			border-bottom: 0px;
			/* border-top: 1px solid #DDD; */
			padding: 5px !important;
		}

		.resources_title {
			@media (min-width: @screen-lg) {
				width: 25%;
			}
			@media (max-width: @screen-lg) {
				width: 35%;
			}
			@media (max-width: @screen-xs) {
				width: 42%;
			}
		}
		.table-multilingual-left {

			display: inline-block;
			@media (max-width: @screen-xs) {
				width: 100%;
			}
		}
		.table-multilingual-right {
			display: inline;
			@media (max-width: @screen-xs) {
				display: hidden;
			}
		}
		//Tabs

		.tabs {
			border-width: 3px;
			margin-right:auto;
			margin-left: auto;
			padding: 0;
		}

		.nav-tabs {
			margin-right: auto;
			margin-left: auto;
			margin-bottom: 20px;
			width: 100%;
			border-color: @blue2;
			border-width: 2px;
			.active a {
				margin-left: 0px;
				border-color: @blue2;
			}
			a {
				font-weight: 700;
				font-size: 105%;
				color: @blue2;
				margin-right: 5px;
				margin-left: 0px;
				margin-bottom: -1px;
				padding: 7px 10px;
				text-wrap: yes;
				@media (min-width: @screen-lg) {
					font-size: 120%;
				}
				@media (max-width: @screen-md) {
					padding: 7px 11px;
				}
				@media (max-width: @screen-sm) {
					padding: 4px 7px;
					font-size: 95%;
				}
				@media (max-width: @screen-xs) {
					margin-right: 3px;
					padding: 3px 2px 3px 2px;
					font-size: 100.1%;
				}
				p {
					@media (max-width: @screen-sm) {
						font-size: 55%;
					}
				}
				.caret {
				  border-top-color: @white1;
				  border-bottom-color: @white1;
				}
			}
			a:hover {
				background-color: @blue2;
				opacity: 1;
				color: @white1;
				border-color: @blue2;
				p {
					color:@white1;
				}
				.caret {
					border-top-color: @white1;
					border-bottom-color: @white1;
				}
			}
			a:focus {
				border-color: @blue2;
				background-color: @blue2;
				color: @white1;
				.caret {
					border-top-color: @white1;
					border-bottom-color: @white1;
				}
			}
			.dropdown-menu-color {
				color: @blue2;
				background-color: @white1;
			}
			.dropdown-ppt {
				background-color: @blue2;
				padding: 1px;
				border-radius: 0;
				a {
					margin-right: 0px !important;
				}
			}

			.mediaTableMenu {
				@media (min-width: @screen-lg) {
					color: @red2;
					right: 15px !important;
				}
			}
		}

		.nav-tabs > .active > a, .nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus {
			color: @white1;
			background-color: @blue2 !important;
			border-color: @blue2;
			outline: none;
			p {
				color: @white1;
			}
		}

	.ctry_photos {
		p {max-width: 150px;
			font-size: 92%;
		}
	}

	.ctry_caste {
		#chartdivCaste {
		  width: 100%;
		  height: 325px;
  			@media (max-width: @screen-sm) {
				height: 175px;
			}
		}
		h5 {
		    margin: 0 0 10px 0;
		}
	}

	.ctry-pray1040 {
		padding: 15px 0;
		font-size: 16px;
		font-weight: bold;
		@media (max-width: @screen-xs) {
			font-size: 13px;
		}		
	}
	.country-progress-tableau {
		max-height: 650px;
		margin: 0px auto 0px;
		padding: 0px;
		@media (max-width: @screen-xs) {
			margin-top: 1px;
			max-height: 1200px;
		}
	}
	.country-religion-tableau {
		max-height: 775px;
		margin: 0px auto 0px;
		padding: 0px;
		@media (max-width: @screen-xs) {
			margin-top: 1px;
			max-height: 1200px;
		}
	}
	.chartDivCtry {
		width: 350px;
		height: 400px;
	}

	.x-axis-label {
		text-align: center;
		margin-top: -5px;
	}

	.ctry-x-axis {
		margin: -7px auto 0px;
		padding-left: 25px;
	}

	.buttons {	// used on south asia drop downs
		padding-bottom: 1.3%;

		.btn-sm {
			background-color: transparent;
			font-size: 200%;
			color: @green1;

			@media (max-width: @screen-sm) {
					font-size: 140%;

					}
			a {
				color: @white1;
				}
			}
		.btn-sm:hover {
			background-color: @gray-lighter;
			font-style: italic;
		}
		.dropdown-menu {
			background-color: @white2;
			width: 350px;
			@media (max-width: @screen-xs) {
				font-size: 125%;
				width: 260px;
			}
			max-height: 350px;
			overflow-y: auto;
			overflow-x: hidden;
			a {
				padding-left: 5px;
				line-height: 100%;
				font-size: 100%;
				word-wrap:break-word;
				display: block;
				white-space: normal;
				@media (max-width: @screen-xs) {
					font-size: 83%;
				}
			}
			a:hover {
				background-color: @blue2;
				color: @white1;
			}
		}
	}

		.religions_list {

			.btn-sm {
				background-color: transparent;
				font-size: 285%;
				margin:5% 0 5% 10%;
				color: @green1;

				@media (max-width: @screen-xs) {
						font-size: 190%;
						margin-left: 0;

						}
				a {
					color: @white1;
					}
				}
			.btn-sm:hover {
				background-color: @gray-lighter;
				}
		}

		.gauge_container {
		    position:relative;
		    display:inline-block;
		    text-align:center;
		    border:1px solid red;

			.button {
			    position:absolute;
			    bottom:10px;
			    right:10px;
			    width:100px;
			    height:30px;
			}
		}


	.dropdown-menu {
		/* width: 100%; */
		a:hover {
			background-color: @blue2;
			color: @white1;
			.sub_test {
				color: @white1;
			}
		}
	}

//Listing sort by drop down
.sort_by {

	.pagination_from_to {
		font-size: 107%;
		display: inline;
		float: left;
		margin-left: -1%;
		margin-top: 1%;
		@media (max-width: @screen-lg) {
			font-size: 95%;
			margin-left: -5%;
		}
	}

	.buttons {
		margin: 0 1% 0 1%;
		float: right;
			@media (max-width: @screen-xs) {
					margin: 0 1% 0 0;
					float: left;
					}

		.dropdown-toggle
			{
			margin: 0px;
			}

		.dropdown-menu {

				background-color: @white2;
				width: 198px;
				font-size: 92%;
				a {
					height: 24px;
					}
				a:hover, a:focus {
					background-color: @blue2;
					color: @white2;
				}

			}
		}

}

//Done listing sort by drop down


// Sizing
// --------------------------------------------------

// Large
// DONE pagination links


//  column choices button
.column {

	@media (max-width: @screen-md) {
		display: none;
	}

	.buttons {
		margin: 0 1% 0% 1%;
		float: right;
			@media (max-width: @screen-sm) {
					float: left;
					margin: 1% 1% 0 0;
					}

		.btn-sm {
			background-color: @white2;
			font-size: 105%;
			color: @orange1;
			height: 30px;
			width: 115px;
			text-align: left;
				@media (max-width: @screen-lg) {
					font-size: 95%;
					}

			a {
				color: @orange1;
				margin-right: 0;
				}
			}

		}

}


//Done listing drop downs and buttons

	p   {
		margin-bottom: 0%;
	}

	.key {
		@media (min-width:@screen-lg) {
				margin-left: 2%;
				}

		.sec_title {
			font-weight: 900;
			font-size: 90%;
			margin-left: 6%;
				a {
					font-size: 85%;
					font-weight: 500;
					text-align: center;

					}
			}
		.key_font {
				font-size: 80%;
			}

	}

	.table-totals {
		margin-top: 1%;
		caption {
			text-align: center;
			padding: 5px 0 5px 0;
			margin: 0 0 10px 0;
			font-size: 90%;
			@media (max-width: @screen-xs) {
				margin: 0 0 10px 0;
				padding: 0;
			}
		}
		.table-sorting {
			display: inline !important;
			@media (max-width: @screen-xs) {
				display: block !important;
				margin: 10px auto;
				text-align: center;
			}
		}
		.table-scale {
			display: inline !important;
			margin-left: 5%;
			@media (max-width: @screen-xs) {
				display: block !important;
				margin: 3px 0 5px 0;
			}
			.dots_no_underline {
				a {
					text-decoration: none;
				}
			}
		}
		th {
			border-top: thin solid @black1 !important;
			border-bottom: thin solid @black1 !important;
			background-color: @gray-lighter;
		}
		tr {
			border-top: thin solid @gray-lighter !important;
			border-bottom: thin solid @gray-lighter !important;
		}
		td {
			border: none;
		}
		tfoot tr td {
			background-color: @gray-lighter;
		}
		tfoot tr td.border-top {
			border-top: thin solid @black1 !important;
			border-bottom: none !important;
			padding: 7px 5px 4px 5px;
		}
		tfoot tr td.border-bottom {
			border-bottom: thin solid @black1 !important;
			border-top: none !important;
			padding: 4px 5px 7px 5px;
		}
		tfoot tr td.border-none {
			border: none !important;
		}

		th[data-sort] {
			cursor:pointer;
		}
	    tr:hover {
	        background-color: @table-bg-hover !important;
      	}
		.center {
			text-align: center;
		}
		.left {
			text-align: left;
			float: none;
		}
		.right {
			text-align: right;
		}
	}

	.table-hover {
		margin-top: 10px;
		@media (max-width: @screen-lg) {
			width: 100%;
		}
		@media (max-width: @screen-xs) {
			margin-top: 5px;
		}
		th, td, tfoot tr td {
			@media (max-width: @screen-xs) {
				font-size: 90%;
			}
		}

		word-wrap: break-word;

		.no_wrap {
			white-space:nowrap;
			@media (max-width:@screen-sm) {
				white-space: normal;
			}
		}

		.profile_thumb {
			width: 30px;
			max-height: 30px !important;
			padding: 0 0 0 0 !important;
			margin: 0;
			@media (max-width:@screen-md) {
				width: 30px;
			}
			@media (max-width:@screen-sm) {
				width: 30px;
			}
			@media (max-width:@screen-xs) {
				width: 30px;
			}
		}
	}
	.table-progress {
		margin-top: 0 !important;
		td:first-child {
			max-width: 25px;
			font-weight: bold;
			a {
				color: @black;
			}
		}
		td:nth-child(2) {
			@media (min-width:@screen-md) {
				width: 41%;
			}
		}
		td:nth-child(3) {
			@media (min-width:@screen-md) {
				width: 26%;
			}
		}
	}
	.table-resources {
		th, td {
			font-size: 100.01% !important;
			@media (max-width:@screen-xs) {
				font-size: 85% !important;
			}
		}
	}
	.all-progress-levels {
		width: 100%;
		@media (min-width: @screen-lg) {
			margin-left: 4%;
			width: 87%;
		}
		.caption {
			max-width: 250px;
			margin: auto;
		}
		.progress-dots-lg {
			font-size: 150%;
		}
		.subtitle {
			font-size: 90%;
			padding: 0 20px;
		}
	}
	.table-scale-modal {
		font-size: 85%;
		@media (max-width:@screen-md) {
			font-size: 90%;
		}
		@media (max-width:@screen-sm) {
			font-size: 100%;
		}
		line-height: 150%;
		margin: 15px 0;
		th {
			background-color: @gray-light;
		}
		th, td {
			border: 1px solid black;
			padding: 5px;
			text-align: left;
			vertical-align: middle;
		}
		th:first-child, td:first-child {
			text-align: center;
			@media (max-width:@screen-md) {
				width: 10%;
			}
			@media (min-width:@screen-lg) {
				width: 5%;
			}
			font-weight: bold;
			a {
				color: @black;
			}
		}
		td:nth-child(2) {
			width: 53%;
		}
		th:nth-child(3), td:nth-child(3) {
			width: 42%;
		}
	}
	.frontier-modal {
		width: 70%;
		@media (max-width:@screen-md) {
			width: 100%;
		}
		margin: 0 auto !important;
	}
	.lang_res_listing {
		width: 95% !important;
		margin-left: 3%;
		@media (min-width: @screen-lg) {
			width: 70% !important;
		}
	}
	.lang-list-heading {
		font-size: 125%;
		font-weight: bold;
		padding: 0;
		margin: 15px 0 0 0;
	}
	.lang-list-subheading {
		font-size: 100%;
	}
	.lang_res_new {
		line-height: 75%;
		font-size: 150%;
		color: @red2;
	}

	.ctry_photos {
		width: 100%;
	}
	.ctry_photos_wrapper {
		margin-bottom: 2%;
		.ctry_pg_images {
			max-width: 145px;
			margin: 0px auto;
			.credits {
				display: none;
			}
			@media (max-width: @screen-sm) {
				 max-width: 135px;
			}
			@media (max-width: @screen-xs) {
				 max-width: 125px;
			}
		}
		.ctry_pg_images:hover {
			.credits {
				display: block;
				font-size: 80%;
				color: @black1;
				text-align: center;
			}
		}
		p {
			text-align: center;
		}
		.progress-1, .progress-2, .progress-3, .progress-4, .progress-5 {
			margin-left: 1px;
		}
	}

	.table-responsive {
		margin: 6% 0 3% 0;
		tr:first-child td {
			border-top:none;
		}
		td:first-child {
			font-weight:bold;
			@media (max-width:@screen-xs) {
				max-width:20%; // making left table column larger for bigger screens
			}
		}
		th {
			font-size: 100%;
		}
	}
}

.map-legend {
	text-align: center;
	div {
		padding: 0;
	}
	@media (min-width:@screen-md) {
		margin-left: 3%;
		margin-right: 3%;
	}
	@media (max-width:@screen-sm) {
		width: 100%;
	}
	@media (max-width:@screen-xs) {
		font-size: 90%;
	}
}

#pg_main {
	.profile_search {
		line-height: 100%;
		/* background-color: @gray; */
	}
	label {
		margin-bottom: 5px;
	}
	blockquote {
		font-size: 140%;
		line-height: 160%;
		/* width: 50%; */
		/* float: right; */
		margin-left: 0;
		margin-right: 10%;
	}
	.panel-group {
		margin-bottom: 0px;
	}
}

.pg_header {
	width: 100%;
	@media (max-width:@screen-md) {
		margin-bottom: 15px;
	}
	p {
		line-height: 175%;
		font-size: 115%;
		@media screen and (max-width: @screen-sm) {
			line-height: 160%;
			font-size: 100%;
		}
	}
	.state_header {
		margin-top: 25px;
		margin-bottom: 15px;
		h2, h3, h4 {
			display: inline !important;
		}
		td:first-child {
			text-align: right;
			padding-right: 10px;
		}
		.state-dist {
			font-size: 125%;
			@media screen and (max-width: @screen-sm) {
				font-size: 100%;
			}
			font-weight: bold;
			white-space: nowrap;
			padding: 5px 10px 0px 0;
		}
		.sub_title {
			font-size: 120%;
		}
	}
	.no-border {
		border: none;
	}
	.sub_title {
		font-size: 55%;
		font-family: @font-family-sans-serif;
		font-weight: normal;
		color: @gray-dark;
		display: inline;
		padding: 10px 0px 0px;
		float: right;
		@media (max-width:@screen-md) {
			font-size: 45%;
		}
		@media (max-width:@screen-sm) {
			display: none;
		}
		a {
			margin-top: -7px;
		}
		a:hover {
			text-decoration: underline;
		}
		.no-underline {
			text-decoration: none !important;
		}
	}
	.sub_title_new {
		padding: 10px 0px;
		img {
			vertical-align: middle;
		}
	}
	.sub_title_language {
		padding: 0px !important;
		vertical-align: top !important;
	}

	.btn-search {
		font-family: @headings-font-family;
		font-size: .95em;
		margin: 10px 0px 10px 7px;
		padding: 2px 7px;
		border-radius: 7px;
		border: 1px solid transparent;
		@media (max-width: @screen-xs) {
			font-size: 95%;
			padding: 2px 6px !important;
			margin: 7px 2px;
		}
	}
	.search-input {
		border: 1px solid @gray-light;
		padding-right: 5px;
		height: 25px;
		@media (max-width: @screen-xs) {
			width: 50%;
		}
	}
	.form-search input[type="text"] {
		padding-left: 5px;
		font-size: .95em;
	}

	.gray {
		color: @gray;
	}
	.btn-group {
		vertical-align: top;
		padding-top: 6px;
	}
	.btn-sm {
		font-size: 150%;
		color: @gray-dark;
		background-color: @offwhite2;
	}
	.dropdown-menu {
		max-height: 350px;
		overflow-y: auto;
		> li {
			> a {
				padding-left: 10px;
			}
		}
	}
	.spacer {
		height: 40px;
		@media (min-width: @screen-lg) {
		display: none;
		}
	}
}

.advanced-search {
	h2 {
		/* margin-bottom: 0 !important; */
		@media (max-width: @screen-xs) {
			margin-bottom: 15px !important;
		}
	}
	h3 {
		text-align: center;
		color: @black; /* @white1 */
		background: @gray-lighter; /* @blue2 */
		padding: 4px 0;
		margin-bottom: 15px;
	}
	.btn {
		font-size: 120%;
		font-family: @font-family-sans-serif;
	}
	.no-results {
		margin: 5px auto;
		text-align: center;
		color: red;
		font-weight: bold;
		font-size: 115%;
	}
	.search-input {
		border: 1px solid @gray-light;
		padding-right: 5px;
		margin-right: 3px;
		height: 27px;
		width: 25%;
		@media (max-width: @screen-xs) {
			width: 50%;
		}
	}
	.form-search input[type="text"] {
		padding-left: 5px;
		font-size: 115%;
	}
	.form-search input[type="radio"] {
		-webkit-appearance: radio;
	}
	.search-limit {
		text-align: center;
		font-size: 115%;
		@media (max-width: @screen-md) {
			font-size: 100%;
			line-height: 170%;
		}

		margin-top: 5px;
		input[type="radio"] {
			margin: 0 3px 5px 0;
		}
		.search-limit-radio {
			padding: 0 15px 0 0;
			white-space: nowrap;
		}
	}
	.search-google {
		float: right;
	}
	.cse input.gsc-search-button, input.gsc-search-button {
		font-size: 14px;
		font-weight: normal;
		background-color: @red2;
		border: 0;
		font-family: @font-family-sans-serif;
		height: 28px;
	}
	table.gsc-search-box {
		max-width: 50%;
		@media (max-width: @screen-xs) {
			max-width: 100%;
		}
	}
	table.gsc-search-box td.gsc-input {
		padding-right: 7px;
	}
	.search-focused {
		tr {
			padding: 10px 0;
		}
		td {
			padding: 10px 5px;
			font-size: 115%;
			white-space: nowrap;
			@media (max-width: @screen-xs) {
				font-size: 95%;
			}
		}
		td:first-child {
			text-align: right;
			font-weight: 700;
		}
		.listing_dropdown {
			color: @gray-dark;
		}
	}
}

.no-results {
	color: @red1;
	font-weight: bold;
}

.pg_index_heading {

	max-width: 767px;
	margin: 2% 0 2% 0%;

	@media screen and (min-width: @screen-md-max) {
		margin-left: 8%;
	}

	p {
		font-size: 125%;
		line-height: 170%;
	}
}

.global_list_header {
	ul {
		text-align: left;
		list-style-type:disc;
		margin: 1% 0;
		padding-left: 20%;
		font-size: 125%;
		line-height: 200%;
		li {
			margin-left: 10%;
		}
	}
	img {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}
.caption {
	font-size: 90%;
	padding-top: 5px;
}
.no_wrap {
	white-space:nowrap;
	overflow:hidden;
}
.all_interactive_map {
	#mapdiv {
		font-weight: normal;
		div {
			line-height: 125%;
		}
		width: 450px;
		height: 300px;
		@media (max-width: @screen-xs) {
			width: 300px;
			height: 200px;
		}
		@media (min-width: @screen-sm) {
			width: 450px;
			height: 300px;
		}
		@media (min-width: @screen-md) {
			width: 600px;
			height: 375px;
		}
		@media (min-width: @screen-lg) {
			width: 700px;
			height: 450px;
		}
	}
}

.interactive_map {
	#mapdiv {
		font-weight: normal;
		div {
			line-height: 125%;
		}
		width: 450px;
		height: 300px;
		@media (max-width: @screen-xs) {
			width: 300px;
			height: 200px;
		}
		@media (min-width: @screen-sm) {
			width: 450px;
			height: 300px;
		}
		@media (min-width: @screen-md) {
			width: 700px;
			height: 500px;
		}
		@media (min-width: @screen-lg) {
			width: 850px;
			height: 550px;
		}
	}
	.caption {
		font-size: 115%;
	}
}

.profile_map {
	#mapdiv {
		font-weight: normal;
		div {
			line-height: 125%;
		}
		width: 440px;
		height: 400px;
		@media (max-width: @screen-xs) {
			width: 275px;
			height: 275px;
		}
	}
}
.map_image {
	max-width: 75%;
	@media (max-width: @screen-md) {
		max-width: 100%;
	}
}

.india_map {
	#mapdiv {
		width: 600px;
		height: 500px;
		@media (max-width: @screen-md) {
			width: 525px;
			height: 525px;
		}
		@media (max-width: @screen-sm) {
			width: 475px;
			height: 475px;
		}
		@media (max-width: @screen-xs) {
			margin-left: 5px;
			width: 350px;
			height: 350px;
		}
	}
}

.india_map_table {
	@media (min-width: @screen-lg) {
		margin-top: 35px;
	}
	h3 {
		font-size: 160%;
		margin-bottom: 5px;
		@media (max-width: @screen-xs) {
			font-size: 115%;
		}
	}
	td {
		padding: 2px 5px;
		font-size: 115%;
		@media (max-width: @screen-xs) {
			font-size: 95%;
		}
	}
	td:first-child {
		text-align: right;
	}
}

.india_districts_table {
	@media (min-width: @screen-lg) {
		width: 90%;
	}
}

.jp_img_wrapper {
	position:relative;
	.profile_image {
		/* max-width:80%; */
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		.credits {
			display: block;
			font-size: 90%;
			color: @black;
			text-align: center;
			padding: 5px 0 0 0;
		}
	}
	.profile_map {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		max-width: 425px;
		max-height: 375px;
		@media (max-width:@screen-xs) {
			width:100%;
		}
	}
	.icon {
		position:absolute;
		top:50%;
		left:76%;
		width:15%;
		margin-top:-7%;
	}
}

.img_submit {
	display: block;
	font-size:100%;
	/* line-height: 175%; */
	/* margin: 1% 0 0 0; */
	text-align: center;
	padding-top: 15px;
	padding-bottom: 20px;
}

.prayer_card_btn {
	text-align: center;
	font-size: 103%;
	.btn {
		/* padding-left: 5px; */
		/* padding-right: 5px; */
		margin-left: 5px;
		margin-right: 5px;
	}
}

/* Stats Table */
.stats {
	margin-bottom: 15px;

	.row {
		margin: 0 0 0 1%;
	}

	.col {
		font-size:100%;
		min-height: 125px;
		padding-right: 5px;
		padding-left: 5px;

		@media screen and(max-width: @screen-lg) { // slight adjustments when getting smaller
			font-size: 90%;
			min-height: 100px;
		}
		@media screen and(max-width: @screen-md) { // slight adjustments when getting smaller
			font-size: 100%;
			min-height: 110px;
		}
		@media screen and(max-width: @screen-sm) { // slight adjustments when getting smaller
			font-size: 100%;
			min-height: 105px;
		}
		@media screen and(max-width: @screen-xs) { // slight adjustments when getting smaller
			font-size: 90%;
			min-height: 100px;
		}

		border: 1px solid @gray-light;
		margin:0 -1px -1px 0;
		text-align: center;
		.title, .title_progress {
			font-family: @font-family-sans-serif;
			font-weight: 700;
			font-size: 115%; /* 87% for uppercase */
			line-height: 125%;
			color:@gray-dark;
			margin: 8px auto !important;
			@media screen and(min-width: @screen-lg) { // slight adjustments when getting smaller
				padding: 3px 5px;
				// background-color: red;
			}
			@media screen and(max-width: @screen-md-max) { // slight adjustments when getting smaller
				font-size: 100%;
				margin-bottom: 3px !important;
				// background-color: yellow;
			}
			@media screen and(max-width: @screen-sm-max) { // slight adjustments when getting smaller
				font-size: 120%;
				margin: 7px auto 7px !important;
				// background-color: orange;
			}
			@media screen and(max-width: @screen-xs-max) { // slight adjustments when getting smaller
				font-size: 120%;
				margin: 10px auto 8px !important;
				// background-color: green;
			}
		}

		.data {
			color: @black;
			font-family: @headings-font-family;
			font-weight:normal;
			font-size: 110%; // changed 01/22/19
			line-height: 135%;
			@media screen and(max-width: @screen-md-max) { // slight adjustments when getting smaller
				font-size: 100%;
			}
			@media screen and(max-width: @screen-sm-max) { // slight adjustments when getting smaller
				font-size: 120%;
			}
			@media screen and(max-width: @screen-xs-max) { // slight adjustments when getting smaller
				font-size: 120%;
			}

			&.nA { // demphasize n/a data
				color: @gray-dark;
			}

			.film_link {
				display: block;
				font-size: 250%;
				text-decoration: none;
				margin-top: -9px;
			}
		}
		&.gauge .title {
			margin-bottom: 10%;
		}
		.caption {
			font-size: 75%;
			padding-top: 0;
			@media screen and(min-width: @screen-lg) {
				font-size: 80%;
			}
			@media screen and(max-width: @screen-sm-max) {
				padding-top: 0;
			}
			@media screen and(max-width: @screen-xs-max) {
				font-size: 85%;
			}
		}
	}

}
/* PG Profile gauge */
.gauge_wrapper {
	font-size:100%;
	position:relative;
	width:120px;
	margin:0 auto;
	@media screen and(max-width: @screen-md-max) { // slight adjustments when getting smaller
		width: 100px;
	}
	.gauge_colors {
		width: 80%;
		@media screen and(max-width: @screen-lg) {
			width: 65%;
		}
		margin:0 auto;
	}

	.progress-1, .progress-2, .progress-3, .progress-4, .progress-5 {
		/* font-size: 110%; */
		padding-bottom: 0;
	}
	.frontier {
		margin: 5px 0;
		color: @red1;
		font-weight: 700;
		font-family: @font-family-sans-serif;
		@media screen and(max-width: @screen-lg) {
			font-size: 90%;
			margin: 5px 0 0 0;
		}
		@media screen and(max-width: @screen-sm-max) {
			font-size: 90%;
			margin: 5px 0 0 0;
		}
		a {
			color: @red1;
		}
	}

} //close gauge wrapper


.powerpoints {
	p {
		margin: 5px 6%;
		@media (max-width: @screen-xs) {
			margin: 5px 2%;
		}
	}
	a {
		/* font-size: 115%; */
		@media (max-width: @screen-xs) {
			font-size: 105%;
		}
	}
}

.prayer_cards {
	font-size: 115%;
	line-height: 180%;
	border-bottom: solid 2px @blue2;
	@media (max-width: @screen-lg) {
		font-size: 100%;
	}
	@media (max-width: @screen-md) {
		font-size: 115%;
	}
	@media (max-width: @screen-xs) {
		font-size: 90%;
	}
}
.ctry_details {
	margin-top: 15px;
	img {
		margin: 10px 0;
		border: 1px solid #000;
	}
	td {
		vertical-align: middle !important;
	}
	.ctry_video {
		padding: 0 !important;
	}
	.video-container {
		width: 100% !important;
	}
}

// Sidebar for listing key

 #sidebar {
 	text-align: left;
 	.nav > li {

 		width: 50%;
		@media (max-width:@screen-lg) {
			width: 100%;
		}

 		background-color:@gray-lighter; /* rgba(248,247,243); */
		.border-top-radius(4px);
		.border-right-radius(4px);
		.border-bottom-radius(4px);
		.border-left-radius(4px);
 		margin-bottom: 3px;
 		margin-top: 3px;
 		padding: 5px 0px 7px 10px;
 		font-size: 110%;

 		a {
	 		display:block;
	 		background:none;
			padding: 0px;
			outline:none;
	 	}

		// KEY glyphicon must be defined here
		.glyphicon {
			display:inline;
			color: @blue1;
			font-size:105%;
			line-height: 100%;
		}
		.non_religious {
			.glyphicon {
				display:inline;
				color:@gray-dark;
				font-size:90%;
				line-height: 118%;
				margin-left: 10%;
			}
		}
		.data_download {
			.glyphicon {
				display:inline;
				color:@orange1;
				font-size:116%;
				line-height: 118%;
			}
		}

		&:hover, &.active {
			background-color:rgba(248,247,243,1);
			a {
				background:none;
			}
 		}
 		&.active {
 			& > a {
 				color:@blue2;
 			}
 		}
 		& > ul {
 			padding: 0px 0 6px 0;
 			a {
 				display:block;
 				font-size:78%;

 				&:hover {
 					text-decoration:none;
 				}
 			}

 			li.active {
 				& > a {
 					font-weight:bold;
 				}
 			}

 		}
	 	// hidden until area is active:
	 	ul {
 			display:none;
	 	}
	 	&.active ul {
	 		display:block;
	 	}

 	} // end Sidebar

	.ethno_map {
		max-width:30%;
	}

	.glyphicon {
		display:block;
		color:@orange1;
		font-size:150%;
		@media (max-width: @screen-sm) {
			font-size:190%;
		}
		margin-bottom:0%;
	}
	.content {
		margin-right:30%;
		p {
			@media (max-width: @screen-lg) {
				margin-left: 0%;
			}
		}
	}

	.gc-maps {
		text-align: center;
		img {
			padding: 2% 0 0;
		}
		p {
			font-size: 115%;
			line-height: 155%;
			margin: 2% 8% 4% 8%;
		}
		.row {
			margin: 2% 0 2% 0;
		}
	}

	.maps {
		.g_map {
			margin: 0 20% 0 20%;
			height: 50%;
			width: 50%;
		}
	} //closing maps

} //closing people_groups

.india-response {
	padding: 10px;
	margin-top: 15px; 
	text-align: center;
	color: #000000;
	background-color: #E6E6E6;
	font-size: 125%;
	font-weight: bold;
	max-width: 1170px;
	@media (max-width: @screen-sm) {
		font-size: 100%;
	}
	a {
		color: #000000; 
		// text-decoration: none; //
	}
}

.india-response-ul {
	padding: 0px 0px 10px 50px !important;
	@media (max-width: @screen-sm) {
		padding: 0px 0px 5px 20px !important;
	}
}

.cartograms {
	p {
		line-height: 150%;
		margin: 10px 10px;
		padding: 0;
	}
}

/* purgecss start ignore */
.people_groups .google_map_filter {
	width: 100%;
	height: 500px;
	margin: 10px auto 0;

	@media (max-width: @screen-sm) {
		height: 350px;
	}
	@media (max-width: @screen-xs) {
		height: 200px;
	}
}
a.btn-primary:visited {
		color: #ffffff;
}	
/* purgecss end ignore */

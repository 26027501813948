/*!
 * Home Custom Sections, etc ::: for JoshuaProject - imported by '../main.less' stylesheet ::: @chuckienorton @180bydesign
*/

// Imporing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

/* Available to all hero areas */

.hero {
	/* background-color: @gray-lighter; */
	margin: 0 auto;
	padding: 0;
	.full_width {
		display:block;
	}
}

.photo_holder {
	max-width: 1170px;
	img {
		width: 100%;
    	height: 100%;
    	pbkect-fit: cover;
	}
	.photos {
		padding: 0;
		margin: 0;
	}
}

.text_scroller {
	background-color: @gray-light;
	height: 65px;
	padding-top: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 145%;
	color: @gray-darker; // @blue2
	font-weight: 700;
	@media (max-width:@screen-sm) {
		font-size: 120%;
	}
	@media (max-width:@screen-xs) {
		font-size: 120%;
		height: 55px;
		align-items: center;
	}
}

.overlay {
	opacity: .8;
	position: absolute;
	text-align: center;
	z-index: 100;
	left:0px;
	bottom:0px;
	color: black;
	background-color: @gray-lighter;
	font-weight: bold;
	padding: 3px 0px 4px 0px;
	width: 100% !important;
	display: block;
	overflow: hidden;
	line-height: 120%;
	font-size: 98%;  /* Possibly include because of long South Asia names wrapping to extra lines */
	@media (max-width:@screen-xs) {
		font-size: 90%;
	}
}

.heading {
	display:block;
	color: @link-color;
	font-size: 115%;
	font-weight: bold;
	padding-top: 10px;
	padding-bottom: 5px;
	text-align: center;
	@media (max-width: @screen-xs) {
		font-size: 100%;
	}
	@media (max-width: @screen-md) {
		font-size: 115%;
	}
	.sub {
		color: @gray-darker;
		font-size: 85%;
		width: 85%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 15px;
		font-style: normal;
		display: block;
		@media (min-width: @screen-md) {
			max-width: 400px;
			font-size: 90%;
		}
		@media (max-width: @screen-xs) {
			width: 95%;
		}
	}
	.sub_fpg {
		width: 100% !important;
	}
	iframe {
		margin: 5px 0;
	}
 }

.gray-background {
	background-color: @gray-lighter;
}

.gray-lighter {
	@media (min-width:@screen-lg) {
		background-color: @gray-lighter;
	}
}
.gray-lighter-mobile {
	@media (max-width:@screen-lg) {
		background-color: @gray-lighter;
	}
}

/* Global Summary */
.global-summary {
	text-align: center;
	font-size: 80%;
	color: @black;
	table {
		text-align: left;
		margin-bottom: 20px;
		td {
			padding: 1px 5px 1px 0;
		}
	}
	hr {
		width: 75%;
		margin: 4px 0;
	}
}
#chartDiv {
	margin-top: -10px;
	width: 250px;
	height: 230px;
}
.x-axis-label {
	text-align: center;
	margin-top: -12px;
}

/* Interactive Map */
.index_map {
	#mapdiv {
		font-weight: normal;
		div {
			line-height: 125%;
		}
		@media (max-width: @screen-xs) {
			width: 300px;
			height: 200px;
		}
		@media (min-width: @screen-sm) {
			width: 450px;
			height: 300px;
		}
		@media (min-width: @screen-md) {
			width: 490px;
			height: 300px;
		}
		@media (min-width: @screen-lg) {
			width: 540px;
			height: 390px;
		}
	}
}

.interactive_map {
	overflow: visible;
	line-height: 125%;
}

img.the-thirty-one {
    margin: 0px 5px 10px 5px;
}


/* Mobile App */
.index-app {
	max-width: 160px;
}

.index-flags {
	margin-bottom: 10px;
	img {
		max-width: 40px;
	}
}

.pgic-pgac {
	width: 100%;
	color: black;
	@media (min-width: @screen-md) {
		font-size: 100%;
	}
	img {
		padding: 10px 0 5px 0;
	}
	.pgic-pgac-sub {
		font-size: 100%;
		@media (min-width: @screen-md) {
			font-size: 75%;
		}		
	}
	.pgic-pgac-sub-small {
		font-size: 95%;
		@media (min-width: @screen-md) {
			font-size: 75%;
		}		
	}	
	.pgic-pgac-counts-all {
		display: flex;
		align-items: center;
		justify-content: center;
		a, a:hover {
			font-size: 210%;
			@media (min-width: @screen-md) and (max-width: @screen-lg) {
				font-size: 150%;
			}	
			font-weight: bold;
			line-height: 1em;
			text-decoration: none;
		}	
	}
	.pgic-pgac-counts-upg {
		display: flex;
		align-items: center;
		justify-content: center;
		a, a:hover {
			font-size: 130%;
			font-weight: bold;
			line-height: 1em;
			text-decoration: none;
		}	
	}
}

/* Frontier Peoples */
.the31 {
	color: @gray-darker;
	font-size: 85%;
	margin-top: 7px;
	td {
		padding: 2px 10px 2px 5px;
		font-weight: 700;
	}
}

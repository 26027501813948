// Imporing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.api {

				h1 {
					color: @green1;
					margin-bottom: 1%;	
				}
				h2 {
					font-size: 180%;
					font-weight: normal;
					margin-bottom: 5px;
					display: block;
					clear: both;
					background-color: @green1;
					color: white;
					padding: 5px;
					margin-top: 20px;
				}
				h3 {
					font-size: 130%;
					margin: 6.5px;
					font-weight: normal;
					padding-bottom: 1px;
					width: 85%;
					color: @gray-darker;
					.glyphicon-chevron-down {
						font-weight: normal;
						color: @green1;
						font-size: 80%;
					}
				}
				h4 {
					font-size: 11pt;
					margin: 4px 0 4px 30px;
					padding: 1px;
					line-height: 1.4;
					color: @gray-darker !important;
					font-weight: normal;

				}
				h5 {
					font-size: 130%;
					margin: 4px 0 4px 30px;
					font-weight: normal;
					padding: 1px;
				}	
				p {
					font-size: 11pt;
					margin: 0;
				}
				a {
					color: @green1;
				}
				a:hover {
					color: @green1;
					background-color: white; 
				}

				.method_links {
					margin-top: 10px;

				}
				.method_links > a {
					display: block;
					color: @green1;

				}
				.field_section {
					display: block;
					padding-bottom: 20px;
					margin-bottom: 20px;
				}
				.fields {
					display: block;
				}
				thead > tr > th {
					color: blue;
					text-decoration: underline;
				}
				table {
					margin-left: 5%;
				}
				tbody > tr >td  {
					border-bottom: solid 1px gray;
					padding: 3px;

				}
				.sidebar {
				}
				.affix {
					max-width: 350px;
					margin-top: 10px;
					width: 32%;
					background-color: @offwhite2;
					color: @gray-darker;
					padding-right: 0;
					max-height: 550px;
					overflow: scroll;
					@media (max-width: @screen-lg) {
						position: relative;
						width: 90%;
						max-width: 595px;
						margin-left: auto;
						margin-right: auto;
					}
				}
				.affix > a {
					color: @gray-darker;
				}
				.response_table {
					font-size: 90%;
					color: white !important;
					width: 100%;
					margin-left: 0px;
				}
				.response_table > thead > tr > .left {
					color: white;
					text-align: center;
				}
				.response_table > tbody > tr > td:first {
					padding: 2px !important;
					margin: 0;
				}
				.code {
					width: 30px;
					border-right: dotted 1px gray;
			
				}
				.panel-group {
					margin: 0;
				}
				.panel {
					background: none;
					color: none;
					border: none;
				}
				.panel > a:hover {
					color: white;
				}
				.collapsed {
					margin: 0;
				}
				.panel > a > h2 {
					margin: 0;
					padding: 0;
				}
				.sample > .panel-body {
					overflow: visible !important;
					max-height: 100%;
				}
				.sample_link {
					color: @green1;
					text-decoration: underline;
					margin-bottom: 20px;
				}
				.sample_link:hover {
					font-weight: bold;
					color: @green1 !important;
				}
				.panel-body {
					max-height: 350px;
					overflow: scroll;
				}
				.panel-body, .panel-body > table, 
					.panel-body > a, .panel-body > table > thead > tr > th {
					background-color: @gray-lighter;
					color: @gray-darker !important;
					padding-left: -50px;
				}
				.panel-body {
					background-color: @gray-lighter !important;
				}
				.model_relationships {
					border-left: 4px dotted gray;
					margin:10px 0 25px 30px;
			
				}
				.permissions {
					border-left: 4px dotted gray;
					margin:10px 0 10px 30px;
				}				
				.glyphicon-lock, .glyphicon-file  {
					color: gray;
				}
				.models > thead >tr > th {
					color: black;
					text-decoration: none;
					width: 180px;
					border: none;
				}
				.name  {
					width: 25%;
				}
				.essential {
					padding-right: 8px;
				}
				.btn-lg {
					background-color: @green1;
					color: white;
					border: solid 15px @green1;
					padding: 0;
					text-decoration: none !important;
				}
				.btn-lg:hover {
					background-color: @green1;
					color: @gray-lighter !important;
				}	
				.mediaTable {
					a {
						color: @green1;
						font-size: 80%;
						word-wrap: break-word;
					}
				}
				.mediaTableMenu,  .mediaTableMenuClosed {
					display: none;
				}
				.essential, .persist{
						select, option {
							background-color: @green1 !important;
						}
				}
				@media (max-width: 1450px) {
					.essential, .persist{
						max-width: 200px;
						word-wrap: break-word;

						select, option {
							max-width: 150px !important;
							word-wrap: break-word;
						}
					}
					.field-type {
						max-width: 100px;
					}
					.value {
						max-width: 210px;
					}
				}	

				.short {
					width: 250px;
				}
				form {
					margin-left: 3%;
					margin-top: 2%;
				}

				-webkit-input-placeholder { /* WebKit browsers */
				    color:    @gray-dark;
				}
				-moz-placeholder { /* Mozilla Firefox 4 to 18 */
				    color:    @gray-dark;
				}
				-moz-placeholder { /* Mozilla Firefox 19+ */
				    color:    @gray-dark;
				}
				-ms-input-placeholder { /* Internet Explorer 10+ */
				    color:    @gray-dark;
				}
				textarea, input {
					color: @gray-darker;
				}
				.btn-md {
					background-color: @orange1;
					color: white;
				}
				.home  {
					p {
						margin-left: 8%;
					}
					a {
						margin-left: 8%;	
						color: @orange1;
					}
				}
				.quick-links {
					a {
						margin-top: 10px !important;
						color: @green1;
						font-style: italic;
						font-size: 90%;
					}
					p {
						margin-top: 10px !important;
						color: @gray-dark;
						font-style: italic;
						display: inline;
						font-size: 90%;
				
					}
				}
				.sample_code {
					
					h5 {
						margin: 1.5% 0 1.5% 0;
					}
					h2 {
						margin-bottom: 4%;
						margin-top: 6%;

					}
				}

				
			//Parsley CSS
							input.parsley-success,
							select.parsley-success,
							textarea.parsley-success {
							  color: #468847;
							  background-color: #DFF0D8;
							  border: 1px solid #D6E9C6;
							}

							input.parsley-error,
							select.parsley-error,
							textarea.parsley-error {
							  color: #B94A48;
							  background-color: #F2DEDE;
							  border: 1px solid #EED3D7;
							}

							.parsley-errors-list {
							  margin: 2px 0 3px 0;
							  padding: 0;
							  list-style-type: none;
							  font-size: 0.9em;
							  line-height: 0.9em;
							  opacity: 0;
							  -moz-opacity: 0;
							  -webkit-opacity: 0;

							  transition: all .3s ease-in;
							  -o-transition: all .3s ease-in;
							  -ms-transition: all .3s ease-in;
							  -moz-transition: all .3s ease-in;
							  -webkit-transition: all .3s ease-in;
							}

							.parsley-errors-list.filled {
							  opacity: 1;
							}
							.parsley-required {
								margin-top: 5px;
								color: #B94A48;
								font-size: 115%;
							}
				//Styling for webkit checkboxes			
			 	input[type=checkbox],
				 input[type=radio] {
				   display: inline-block;
				   font-size: 15px;
				   line-height: 1em;
				   margin: 0 0.25em 0 0;
				   padding: 0;
				   width: 1.25em;
				   height: 1.25em;
				   -webkit-border-radius: 0.25em;
				   vertical-align: text-top;
				   border: solid 1px @gray-dark;
				 }

				 input[type=radio] {
				    -webkit-border-radius: 2em; /* Make radios round */
				  }
				  input[type=checkbox]:checked, input[type=radio]:checked {
				   background: url("/assets/img/small-checked-checkbox-md.png") no-repeat center center;


				   -webkit-background-size: 28px 28px; /* shrink high resolution background */
				 }	



}

@charset "UTF-8";

/*!
 * Joshua Project
 *
 * Copyright 2013 - all rights unreserved
 * Licensed under none. seriously. Feel free to go wild with this.
 * http://joshuaproject.net
 *
 * Designed and built with all the love in the world by @chuckienorton @180bydesign
 * NOTE: The ~ says load from the node_modules directory. To install use npm install.  See README for
 * more details.
 */


// Core variables (has all the easy-to-change goodies)

@import "variables.less";
@import "includes/fonts";
@import "includes/icons";
@import "~bootstrap/less/mixins.less";

/*********************************************
*********************************************

	And here we go Joshua Project...

*********************************************
********************************************/


html {
	overflow-x: hidden;
}

body {
	background: #FFF; /* @body-bg url(@body-bg-img) repeat; */
	padding-top: @navbar-height;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
	overflow: hidden;
	@media print {
		padding:0;
	}
	// Override boostrap's print url after link
	@media print {
		a[href]:after {
		  content: none !important;
		}
	}
}

.content {
	max-width: 767px;
	@media (min-width:@screen-lg) {
		margin-left: 4%;
	}
}

/* Links (many link styles are found in bootstrap, variables, etc... these are overarching defaults */
a {
	color: @link-color;
	outline: none;
	&:hover, &:focus, &:active {
		color: @link-hover-color;
		text-decoration: @link-hover-decoration;
		outline: none;
	}
	&:visited {
		color: @link-visited-color;
	}
}

sup {
    vertical-align: super;
    font-size: smaller;
}

.btn {
	font-family: @font-family-sans-serif;
	margin: 10px 7px 10px 7px;
	padding: 4px 7px !important;
	font-size: 115%;
	border-radius: 7px;
	@media (max-width: @screen-xs) {
		font-size: 105%;
		padding: 4px 6px !important;
		margin: 12px 2px;
	}
}
.btn-primary {
	color: @white1;
	background-color: @red2;
	border-color: @red2;
 	&:focus {
 		background-color: @red2;
		border-color: @red2;
		outline: none;
 	}
 	&:hover {
 		background-color: @gray;
		border-color: @gray;
 	}
	&:visited, a:visited {
		color: @white1 !important;
	}
}

.btn-donate {
	font-size: 125%;
	margin: 0 auto 0;
	padding: 4px 15px !important;
}

.btn-xs {
	@media (max-width: @screen-xs) {
		font-size: 95%;
	}
}

hr {
	border-top: 1px solid @gray-lighter;
}

/* Interior Main Defaults */

// font-size: 100%;

h1 {
	color: @red2;
	font-size: 215%;
	margin: 2.5% 0 2% 0%;
	.glyphicon {
		display:inline;
		vertical-align: top;
		color:@red2;
		font-size:100%;
		padding-right: 10px;
	}
	@media (max-width: @screen-xs) {
		font-size: 175%;
		padding-top: 10px;
	}
	@media (min-width: @screen-lg) {
		margin-left: 4%;
	}
}

h2 {
	color:@red2;
	font-family: @headings-font-family;
	font-size: 215%;
	font-weight: normal;
	margin-top: 25px;
	margin-bottom: 20px;
	a:hover {
		text-decoration: none;
		color: @link-hover-color;
		.link-text {
			text-decoration: underline;
			color: @link-hover-color;
		}
	}
	a:visited {
		color: @link-hover-color;
	}
	.in-color-fix {
		color: @gray !important;
	}
	.glyphicon {
		display:inline;
		a, a:hover {
			color: @link-hover-color;
		}
		font-size:95%;
		padding-right: 10px;
		vertical-align: top;
	}
	.icon {
		display:inline;
		a {
			color: @link-hover-color;
		}
		font-size:85%;
		padding-right: 10px;
	}
	@media (max-width: @screen-md) {
		font-size: 215%;
	}
	@media (max-width: @screen-sm) {
		font-size: 155%;
	}
	@media (max-width: @screen-xs) {
		font-size: 175%;
		margin: 20px 0 10px 0 !important;
		line-height: 125%;
	}
	@media (min-width: @screen-lg) {
		/* margin-left: 4% !important; removed 5/25/23 */
		margin-left: 5px;
	}
}

h3 {
	color:@gray-darker;
	font-size: 150%;
	line-height: 1.5;
	font-weight: normal;
	margin: 7px 0;
	@media (min-width: @screen-lg) {
		/* margin-left: 4%; */
	}
	@media (max-width: @screen-xs) {
		font-size: 120%;
		line-height: 150%;
	}
}

h4 {
	color: @gray-darker;
	font-size: 150%;
	margin-top: 15px;
	margin-bottom: 7px; 
	font-family: @font-family-sans-serif;
	@media (max-width: @screen-xs) {
		font-size: 120%;
		line-height: 130%;
	}
}
h5 {
	color: @gray-darker;
	font-size: 125%;
	font-family: @font-family-sans-serif;
	line-height: 140%;
	margin: 0 auto 10px;
	padding: 0;
	max-width: 90%;
	@media (min-width: @screen-lg) {
		max-width: 80%;  // Was 75% changed 05/10/22, hope it does not impact other places
	}
	@media (max-width: @screen-xs) {
		max-width: 96%;
		font-size: 125%;
		margin: 0 auto 5px;
	}
}
h6 {
	font-size: 125%;
	font-family: @font-family-sans-serif;
	/* font-weight: normal; */
	color: @gray-darker;
	margin: 0px 0px 0.5%;
	padding-bottom: 0px;
}
h7  {
	color: @black;
	font-size:125%;
	font-weight: bold;
	font-family: @font-family-sans-serif;
	@media (max-width: @screen-sm) {
		font-size: 110%;
	}
}
h8 {
	font-size: 115%;
	color: @red2;
	font-weight: bold;
	line-height: 130%;
	margin: 0;
	padding: 0;
}
h9 {
	font-size: 115%;
	color: @gray-darker;
	font-weight: bold;
	line-height: 130%;
	margin: 0;
	padding: 0;
}
p {
	font-size: 115%;
	font-weight: 400;
	margin: 0 0 2% 0;
	line-height: 180%;
	/*
	@media (max-width: @screen-xs) {
		font-size: 105%;
		line-height: 160%;
	}
	*/
}
p.widget-note {
	font-size: 75%;
	font-style: italic;
	text-align: left;
}
blockquote {
	color:@red2;
	border-left: 4px solid @red2;
	padding: 0 0 0 3%;
	margin:  0 0 3% 0;
	font-style: italic;
	p {
		font-size: 150%;
		@media (max-width: @screen-xs) {
			font-size: 125%;
			line-height: 150%;
		}
		line-height: 175%;
		padding-left:0;
		padding-right:0;
		margin-left:0;
		padding-left:0;
	}
}
dd {
	a:hover {
		text-decoration: none;
		.link-text {
			text-decoration: underline;
		}
	}
	font-size: 115%;
	margin: 0 0 20px 2%;
	@media (max-width: @screen-xs) {
		font-size: 100%;
	}
	@media (min-width: @screen-lg) {
		margin: 0 0 15px 15%;
	}
	.glyphicon, .icon {
		display:inline;
		color: @link-color;
		padding-right:10px;
		a, a:hover {
			text-decoration: none !important;
		}
	}
	ul {
		padding: 10px 0px 0px 50px;
		@media (max-width: @screen-xs) {
			padding: 10px 0px 0px 20px;
		}
		list-style-type: disc;
		li {
			padding: 5px 0;
		}
	}
	img {
		vertical-align: baseline;
	}
}
.bold {
	font-weight: bold;
}
.img-icon-size {
	width: 25px;
	height: 25px;
	/* padding-right: 5px; */
	/* padding-bottom: 5px; */
	margin-right: 5px;
	margin-bottom: 2px;
	vertical-align: top;
}
.bullets-numbered {
	ol {
		margin: 0 0 0 3%;
		list-style-type: decimal;
		font-size: 91%;
		line-height: 170%;
		li {
			padding: 0 10px 1.5% 10px;
			p {
				margin-left: 3%;
			}
			ul {
				margin: 10px 0 0 5%;
				li {
					list-style-type: disc;
					line-height: 160%;
					font-size: 100%;
				}
			}
		}
	}
}
.bullets-normal {
	padding: 0 0 0 6%;
	ul li {
		list-style-type: disc;
	}
	ol li {
		list-style-type: decimal;
	}
	ul, ol {
		margin: 0 0 .5% 3%;
		@media (max-width: @screen-xs) {
			margin: 0 0 3% 2%;
		}
		li {
			padding: 0 0 5px .5%;
			font-size: 115%;
			line-height: 170%;
			@media (max-width: @screen-sm) {
				font-size: 90%;
			}
			@media (max-width: @screen-xs) {
				font-size: 85%;
			}
			p {
				margin-left: 3%;
			}
			ul {
				margin: 0 0 0 6%;
				@media (max-width: @screen-xs) {
					margin: 0 0 0 15%;
				}
				li {
					list-style-type: square;
					line-height: 160%;
					font-size: 100%;
				}
			}
		}
	}
}
.bullets-articles {
	li {
		padding: 0 0 3% .5% !important;
	}
}
.bullets-footer {
	display: block;
	max-width: 685px;
	margin: 10px auto 20px;
	line-height: 140%;
	@media (max-width: @screen-md) {
		padding: 10px;
	}
	@media (max-width: @screen-xs) {
		line-height: 160%;
		font-size: 100%;
	}
	text-align: left;
	ol, ul {
		margin: 0px 0px 0px 15px;
		list-style-type: decimal;
		font-size: 80%;
		@media (max-width: @screen-xs) {
			font-size: 90%;
		}
		li {
			padding-left: 5px;
			padding-bottom: 5px;
		}
	}
}
.clear {
	float: clear;
}

.equal-height {
    display: flex;
    flex-wrap: wrap;
}

.equal-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 60px;
	overflow: hidden;
	margin-top: 15px;
}

.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.widget-container {
	margin-top: 35px;
}

.pray-fix {
	@media (min-width:@screen-lg) {
		margin-left: 30px;
	}
}
.custom-fix {
	margin-top: 15px;
	font-size: 115%;
	line-height: 140%;
}
.help-fix {
	margin-bottom: 50px;
	@media (min-width:@screen-lg) {
		margin-left: 10px;
		margin-right: -30px;
	}
}
.center {
	text-align: center;
}
.flip_h {
	display: inline-block;
	transform: scale(-1, 1);
}
.flip_v {
	display: inline-block;
	color: @red2;
	font-size: 175%;
	font-family: @font-family-sans-serif;
	padding: 5px 0 12px;
	-moz-transform:rotate(-180deg);
	-webkit-transform:rotate(-180deg);
	-ms-transform:rotate(-180deg);
	-o-transform:rotate(-180deg);
	transform:rotate(-180deg);
}
.hide {
	visibility:collapse;
}
.hidden_xs {
	display: inline !important;
	@media (max-width: @screen-xs) {
		display: none !important;
	}
}
.random-caption {
	/*background-color: @gray-lighter; */
	max-width: 300px;
	margin-left: 15px;
	margin-right: 15px;
	padding: 20px 5px 15px;
	@media (max-width: @screen-xs) {
		padding-top: 0;
	}
	img {
		max-width: 200px;
		@media (max-width: @screen-xs) {
			padding-top: 15px;
			max-width: 165px;
		}
	}
	.caption {
		margin: 0px 5px 10px;
		font-size: 115% !important;
		/* font-weight: 900; */
	}
	table {
		font-size: 100%;
		line-height: 170%;
		td:first-child {
			text-align: left;
			padding-right: 10px;
		}
	}
	.btn {
		font-family: @font-family-sans-serif;
		color: @white1;
		font-weight: 500;
		background-color: @red2;
		border-color: @red2;
		padding: 4px 10px !important;
		font-size: 115%;
		border-radius: 7px;
		@media (max-width: @screen-xs) {
			font-size: 95%;
			padding: 2px 6px !important;
			margin: 7px 2px;
		}
	}
}
.progress-dots {
	font-size: 160%;
	@media (max-width: @screen-sm) {
		font-size: 130%;
	}
	@media (max-width: @screen-xs) {
		font-size: 125%;
	}
	margin-top: 0px;
	line-height: .75;
}
.progress-dots-lg {
	vertical-align: calc;
	font-size: 115%;
	padding-left: 3px;
}
.progress-dots-th {
	font-weight: 400;
	vertical-align: text-top;
}

/* purgecss start ignore */

.progress-0 {
	/* display: none; */
	vertical-align: middle;
	display: inline-block;
	color: @progress1;
}
.progress-1 {
	color: @progress1;
	a {
		color: @progress1;
	}
}
.progress-2 {
	color: @progress2;
	a {
		color: @progress2;
	}
}
.progress-3 {
	color: @progress3;
	a {
		color: @progress3;
	}
}
.progress-4 {
	color: @progress4;
	a {
		color: @progress4;
	}
}
.progress-5 {
	color: @progress5;
	a {
		color: @progress5;
	}
}
.progress-bg {
	a {
			display:block;
	    text-decoration:none;
	    font-weight: bold;
	    font-size: 120%;
	    line-height: 110%;
	}
}
.progress-bg-1 {
	background-color: @progress1;
}
.progress-bg-2 {
	background-color: @progress2;
}
.progress-bg-3 {
	background-color: @progress3;
}
.progress-bg-4 {
	background-color: @progress4;
}
.progress-bg-5 {
	background-color: @progress5;
}

/* purgecss end ignore */

.red {
	color: @red2;
	font-weight: bold;
	font-size: 120%;
}
.prayer-cards {
	display: inline !important;
}
.modal {
  	text-align: center;
	overflow: hidden;
	@media (max-width:@screen-sm) {
		overflow-y: scroll;
		padding-top: 25px;
	}
}

body.modal-open, .modal-open .navbar-fixed-top, .modal-open .navbar-fixed-bottom {
	margin-right: 0;
}

@media screen and (min-width: @screen-sm) {
  .modal:before {
	display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  	display: inline-block;
  	padding-top: 50px;
	width: 600px;
	@media (max-width:@screen-md) {
		max-width: 70% !important;
	}
	@media (max-width:@screen-sm) {
		max-width: 80% !important;
	}
	@media (max-width:@screen-xs) {
		max-width: 95% !important;
	}
}

.modal-standard {
	font-size: @font-size-base;
	font-family: @font-family-sans-serif;
	line-height: 175%;
	@media (max-width:@screen-sm) {
		font-size: 90%;
	}
	@media (max-width:@screen-xs) {
		.progress-1, .progress-2, .progress-3, .progress-4, .progress-5 {
			font-size: 160%;
		}
	}
	.modal-header, .modal-footer {
		text-align: left !important;
		padding: 7px 0px 7px 10px;
		background-color: @blue2;
	}
	.modal-title {
		color: @white1;
		font-weight: bold;
		font-size: 125%;
		@media (max-width: @screen-xs) {
			font-size: 115%;
		}
	}
	.modal-body {
		text-align: left;
		padding: 10px 12px 15px 12px;
		.modal-body-section {
			color: @red2;
			font-weight: bold;
			padding-right: 5px;
			font-size: 115%;
		}
		ul {
			margin-left: 15px;
			li {
				font-size: 100%;
			}
		}
		div {
			margin: 5px 0;
		}
		h4 {
			margin-top: 0px;
			line-height: 150%;
		}
	}
	.modal-footer {
		padding: 5px 15px 5px 5px;
		background-color: @red2;
	}
	.modal-footer-close {
		font-size: 125%;
		font-weight: bold;
		.opacity(.4);
		&:hover, &:focus {
			color: @close-color;
			text-decoration: none;
			cursor: pointer;
			/* .opacity(.9); */
		}
	}
	.modal-security {
		font-size: 90%;
		max-width: 475px;
		width: 475px;
	}
	.modal-screening {
		font-size: 80%;
		@media (max-width: @screen-md) {
			max-width: 90% !important;
			width: 90%;
		}
		@media (max-width: @screen-sm) {
			max-width: 95% !important;
			width: 95%;
		}
	}
	.modal-map {
		max-width: 500px;
		width: 500px;
	}
	
	/* purgecss start ignore */

	.modal-map-filter {
		/* margin: 0; Removed 11/21/22 */
		padding: 0;
		width: 80%;
		max-width: 1000px !important;
		@media (max-width: @screen-lg) {
			max-width: 95% !important;
			width: 95%;
		}
		.modal-body {
			margin: 0 !important;
			padding: 0 !important;
		}
		div {
			margin: 0;
		}
	}

	/* purgecss end ignore */

	.modal-scale {
		max-width: 660px;
		width: 660px;
	}
	.modal-subgroups {
		max-width: 500px;
		width: 500px;
	}
	.modal-symbols {
		max-width: 450px;
		width: 450px;
	}
	.fine-print {
		font-size: 85%;
		font-weight: normal;
	}
	.close {
		right: 0;
		margin: -22px -14px 0 0;
		.opacity(1);

		&:hover,
		&:focus {
			cursor: pointer;
			.opacity(1);
		}

		// Additional properties for button version
		// iOS requires the button element instead of an anchor tag.
		// If you want the anchor version, it requires `href="#"`.
		button& {
			padding: 0;
			cursor: pointer;
			background: transparent;
			border: 0;
			-webkit-appearance: none;
		}
	}
}

.contact_form {
	margin-left: 8%;
}
.right {
	text-align: right;
}
.left {
	text-align: left;
}

.anchor {
	scroll-margin-top: 89px; // was 95px
	scroll-snap-margin-top: 89px; // was 95px
}

.terms-of-use {
	p {
		font-size: 16px;
	}
	ul {
		list-style-type: disc;
		font-size: 16px;
	}
	li {
		margin: 0 0 0 4%;
		line-height: 170%;
		font-size: 16px;
	}
}
#load{
	margin-top: -50px;
	width:100%;
	height:100%;
	position:fixed;
	z-index:100000;
	background:url("/assets/img/loading.gif") no-repeat center center rgba(0,0,0,0.25);
	visibility: hidden;
}

.fa-black {
	color: black;
}

.imageBoxes {
	margin-left: 5%;
	margin-right: 5%;
	max-width: 1000px;
	.imageBox {
		float: left;
		max-width: 20%;
		img {
			padding: 0;
			margin: 0;
		}
		:hover {
			opacity: .80;
		}
	}
}

/* purgecss start ignore */
// This dialog is created in Javascript
#survey-modal .modal-body {
    background-color: #ffffff;
	.btn {
		padding: 5px 10px !important;
		font-size: 130% !important;
		font-weight: 600;
	}
}

/* purgecss end ignore */

/* For debugging widths */
/*
background-color: transparent;
@media (max-width:@screen-xlg) {
	background-color: blue;
}
@media (max-width:@screen-lg) {
	background-color: red;
}
@media (max-width:@screen-md) {
	background-color: yellow;
}
@media (max-width:@screen-sm) {
	background-color: orange;
}
@media (max-width:@screen-xs) {
	background-color: green;
}
*/
/* @import "chatbot.less"; removed 09/01/22 */

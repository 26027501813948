/*! jQuery UI - v1.11.3 - 2015-03-04
* http://jqueryui.com
* Copyright 2015 jQuery Foundation and other contributors; Licensed MIT */

.ui-helper-hidden {
    display: none
}
.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}
.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse
}
.ui-helper-clearfix:after {
    clear: both
}
.ui-helper-clearfix {
    min-height: 0
}
.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0)
}
.ui-front {
    z-index: 100
}
.ui-state-disabled {
    cursor: default!important
}
.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat
}
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
.ui-accordion .ui-accordion-header {
    display: block;
    cursor: pointer;
    position: relative;
    margin: 2px 0 0 0;
    padding: .5em .5em .5em .7em;
    min-height: 0;
    font-size: 100%
}
.ui-accordion .ui-accordion-icons {
    padding-left: 2.2em
}
.ui-accordion .ui-accordion-icons .ui-accordion-icons {
    padding-left: 2.2em
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    left: .5em;
    top: 50%;
    margin-top: -8px
}
.ui-accordion .ui-accordion-content {
    padding: 1em 2.2em;
    border-top: 0;
    overflow: auto
}
.ui-button {
    display: inline-block;
    position: relative;
    padding: 0;
    line-height: normal;
    margin-right: .1em;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    overflow: visible
}
.ui-button,
.ui-button:link,
.ui-button:visited,
.ui-button:hover,
.ui-button:active {
    text-decoration: none
}
.ui-button-icon-only {
    width: 2.2em
}
button.ui-button-icon-only {
    width: 2.4em
}
.ui-button-icons-only {
    width: 3.4em
}
button.ui-button-icons-only {
    width: 3.7em
}
.ui-button .ui-button-text {
    display: block;
    line-height: normal
}
.ui-button-text-only .ui-button-text {
    padding: .4em .8em
}
.ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
    padding: .4em;
    text-indent: -9999999px
}
.ui-button-text-icon-primary .ui-button-text,
.ui-button-text-icons .ui-button-text {
    padding: .4em 1em .4em 2.1em
}
.ui-button-text-icon-secondary .ui-button-text,
.ui-button-text-icons .ui-button-text {
    padding: .4em 2.1em .4em 1em
}
.ui-button-text-icons .ui-button-text {
    padding-left: 2.1em;
    padding-right: 2.1em
}
input.ui-button {
    padding: .4em 1em
}
.ui-button-icon-only .ui-icon,
.ui-button-text-icon-primary .ui-icon,
.ui-button-text-icon-secondary .ui-icon,
.ui-button-text-icons .ui-icon,
.ui-button-icons-only .ui-icon {
    position: absolute;
    top: 50%;
    margin-top: -8px
}
.ui-button-icon-only .ui-icon {
    left: 50%;
    margin-left: -8px
}
.ui-button-text-icon-primary .ui-button-icon-primary,
.ui-button-text-icons .ui-button-icon-primary,
.ui-button-icons-only .ui-button-icon-primary {
    left: .5em
}
.ui-button-text-icon-secondary .ui-button-icon-secondary,
.ui-button-text-icons .ui-button-icon-secondary,
.ui-button-icons-only .ui-button-icon-secondary {
    right: .5em
}
.ui-buttonset {
    margin-right: 7px
}
.ui-buttonset .ui-button {
    margin-left: 0;
    margin-right: -.3em
}
input.ui-button::-moz-focus-inner,
button.ui-button::-moz-focus-inner {
    border: 0;
    padding: 0
}
.ui-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: none
}
.ui-menu .ui-menu {
    position: absolute
}
.ui-menu .ui-menu-item {
    position: relative;
    margin: 0;
    padding: 3px 1em 3px .4em;
    cursor: pointer;
    min-height: 0;
    list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7")
}
.ui-menu .ui-menu-divider {
    margin: 5px 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-width: 1px 0 0 0
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
    margin: -1px
}
.ui-menu-icons {
    position: relative
}
.ui-menu-icons .ui-menu-item {
    padding-left: 2em
}
.ui-menu .ui-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: .2em;
    margin: auto 0
}
.ui-menu .ui-menu-icon {
    left: auto;
    right: 0
}
.ui-selectmenu-menu {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: none
}
.ui-selectmenu-menu .ui-menu {
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 1px
}
.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
    font-size: 1em;
    font-weight: bold;
    line-height: 1.5;
    padding: 2px 0.4em;
    margin: 0.5em 0 0 0;
    height: auto;
    border: 0
}
.ui-selectmenu-open {
    display: block
}
.ui-selectmenu-button {
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    cursor: pointer
}
.ui-selectmenu-button span.ui-icon {
    right: 0.5em;
    left: auto;
    margin-top: -8px;
    position: absolute;
    top: 50%
}
.ui-selectmenu-button span.ui-selectmenu-text {
    text-align: left;
    padding: 0.4em 2.1em 0.4em 1em;
    display: block;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.ui-slider {
    position: relative;
    text-align: left
}
.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    -ms-touch-action: none;
    touch-action: none
}
.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0
}
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit
}
.ui-slider-horizontal {
    height: .8em
}
.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
    margin-left: -.6em
}
.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%
}
.ui-slider-horizontal .ui-slider-range-min {
    left: 0
}
.ui-slider-horizontal .ui-slider-range-max {
    right: 0
}
.ui-slider-vertical {
    width: .8em;
    height: 100px
}
.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em
}
.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%
}
.ui-slider-vertical .ui-slider-range-min {
    bottom: 0
}
.ui-slider-vertical .ui-slider-range-max {
    top: 0
}
.ui-spinner {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    vertical-align: middle
}
.ui-spinner-input {
    border: none;
    background: none;
    color: inherit;
    padding: 0;
    margin: .2em 0;
    vertical-align: middle;
    margin-left: .4em;
    margin-right: 22px
}
.ui-spinner-button {
    width: 16px;
    height: 50%;
    font-size: .5em;
    padding: 0;
    margin: 0;
    text-align: center;
    position: absolute;
    cursor: default;
    display: block;
    overflow: hidden;
    right: 0
}
.ui-spinner a.ui-spinner-button {
    border-top: none;
    border-bottom: none;
    border-right: none
}
.ui-spinner .ui-icon {
    position: absolute;
    margin-top: -8px;
    top: 50%;
    left: 0
}
.ui-spinner-up {
    top: 0
}
.ui-spinner-down {
    bottom: 0
}
.ui-spinner .ui-icon-triangle-1-s {
    background-position: -65px -16px
}

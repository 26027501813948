@font-face {
  font-family: 'jp_icons';
  src:url('/assets/fonts/jp_icons.eot');
  src:url('/assets/fonts/jp_icons.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/jp_icons.woff') format('woff'),
    url('/assets/fonts/jp_icons.ttf') format('truetype'),
    url('/assets/fonts/jp_icons.svg#jp_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?hfhx57');
  src:  url('/assets/fonts/icomoon.eot?hfhx57#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?hfhx57') format('truetype'),
    url('/assets/fonts/icomoon.woff?hfhx57') format('woff'),
    url('/assets/fonts/icomoon.svg?hfhx57#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'jp_icons';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ico-icon"], [class*=" ico-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-icon-clipboard:before {
  content: "\e900";
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.icon-icon_twitter, .icon-icon_serve, .icon-icon_search, .icon-icon_research, .icon-icon_printer, .icon-icon_pray, .icon-icon_people, .icon-icon_media, .icon-icon_mapflag, .icon-icon_google, .icon-icon_gauge, .icon-icon_filter, .icon-icon_facebook, .icon-icon_droparrow, .icon-icon_credits, .icon-icon_close {
  font-family: 'jp_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.icon-icon_twitter:before {
  content: "\e000";
}
.icon-icon_serve:before {
  content: "\e001";
}
.icon-icon_search:before {
  content: "\e002";
}
.icon-icon_research:before {
  content: "\e003";
}
.icon-icon_printer:before {
  content: "\e004";
}
.icon-icon_pray:before {
  content: "\e005";
}
.icon-icon_people:before {
  content: "\e006";
}
.icon-icon_media:before {
  content: "\e007";
}
.icon-icon_mapflag:before {
  content: "\e008";
}
.icon-icon_google:before {
  content: "\e009";
}
.icon-icon_gauge:before {
  content: "\e00a";
}
.icon-icon_filter:before {
  content: "\e00b";
}
.icon-icon_facebook:before {
  content: "\e00c";
}
.icon-icon_droparrow:before {
  content: "\e00d";
}
.icon-icon_credits:before {
  content: "\e00e";
}
.icon-icon_close:before {
  content: "\e00f";
}

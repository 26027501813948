/*!
 * Frontier People Group Styling ::: for JoshuaProject - imported by '../main.less' stylesheet 
 */
 
// Importing Variables & Mixins 
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.frontier {
	h2 {
		margin-bottom: 10px !important;
		@media (max-width:@screen-xs) { 
			font-size: 150%;
			margin-bottom: 0px !important;
		}
		@media (max-width:@screen-sm) { 
			font-size: 175%;
			margin-bottom: 0px !important;
		}			
	}

	h3 {
		color: @red2;
		text-align: center;
		font-weight: bold;
		font-family: @font-family-sans-serif;
		padding-top: 7px;
		@media (max-width:@screen-sm) { 
			font-size: 135%;
		}				
	}
	h4 {
		color: @black;
		text-align: center;
		font-weight: bold;
		font-family: @font-family-sans-serif;
		font-size: 140%;
		margin: 0 auto 10px;	
	}

	h5 {
		font-size: 115%;
		line-height: 125%;
		margin-bottom: 10px;
		max-width: 85%;
		@media (max-width:@screen-xs) { 
			font-size: 125%;
			line-height: 160%;
			max-width: 100%;
		}			
	}

	.listing-caption {
		font-size: 85%;
	}

	.frontier-peoples {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 0;
		@media (max-width:@screen-xs) { 
			font-size: 92%;
		}				
	}
	.frontier-map {
		margin: 8px auto;
		max-width: 800px;
	}

	.frontier-menu {
		line-height: 225%;
		font-weight: 700;
	}

	.frontier-prayer-cards {
		.btn {
			margin: 6px 0;
			font-size: 100.1%;
		}
	}
	.fpg {
		font-size: 110%;
		@media (max-width: @screen-xs) {
			font-size: 95%;
		}
		line-height: 150%;
		.sub {
			font-size: 90%;
			line-height: 150%;
			font-weight: bold;
			max-width: 70% !important;
			margin: 0 auto 5px;
			padding: 0;
		}
		p {
			font-size: 100%;
			margin: 0 5px 10px 0 !important;
		}
		li {
			margin-left: 40px;
			list-style-type: disc;
		}
	}

	.fpg-resources {
		img {			
			max-width: 80%;
			max-height: 115px;
			@media (max-width: @screen-md) {
				max-width: 100%;
				max-height: 130px;
			}	
			@media (max-width: @screen-xs) {
				max-width: 90%;
				max-height: 115px;
			}				
		}
		.fpg-menu {
			div {
				white-space: nowrap;
				margin-top: 10px;
				min-height: 145px;
				@media (max-width: @screen-md) {
					min-height: 145px;
				}					
			}
		}
		.fpg-subtitle {
			margin-top: -20px;
			font-size: 85%;
		}
		.fpg-year-social {
			display: inline-table;
			padding: 0;
			margin: 0;
			max-width: 100%;
			img {
				max-width: 100%;
			}
			@media (max-width: @screen-xs) {
				max-width: 45%;
			}	
		}
	}

	.fpg-listing-img {
		max-width: 165px;
		@media (max-width: @screen-xs) {
			max-width: 100%;
		}		
	}

	.fpg-ways {
		padding-bottom: 10px;
		font-weight: bold;
	}

	.fpg-handouts {
		font-weight: bold;
		font-size: 110%;
		img {
			padding-bottom: 5px;
			padding-top: 10px;
			max-height: 200px;
		}
		.sub {
			font-size: 85%;
			margin-bottom: 0px;
		}
		div {
			margin-bottom: 15px;	
		}
	}

	.fpg-guide {
		font-weight: bold;
		margin-top: 10px;
		@media (min-width: @screen-lg) {
			margin-left: auto;
			margin-right: auto;
		}
		.sub {
			font-size: 90%;
			margin: 10px 0;
			@media (max-width: @screen-xs) {
				font-size: 110%;
			}
		}
		.fpg-guide-version {
			display: inline-table;
			padding: 0px;
			@media (max-width: @screen-xs) {
				padding: 0;
			}						
		}
		.fpg-guide-version-old {
			white-space: nowrap;
			img {
				max-height: 165px;
			}
		}
		.fpg-prayer-guide-flags {
			td {
				padding: 5px 10px;
				text-align: center;
				white-space: nowrap;
			}
		}
		.fpg-guide-order {
			padding-top: 20%;
			@media (max-width: @screen-md) {
				padding-top: 10%;
			}			
		}
		p {
			margin: 5px 0 0 0;
			font-size: 110%;
			font-weight: bold;
			@media (max-width: @screen-xs) {
				font-size: 110%;
			}			
		}
		img {
			@media (max-width: @screen-xs) {
				max-height: 225px;
			}						
		}	
	}

	.the31 {
		color: @gray-darker;
		margin-top: 7px;
		td {
			padding: 2px 10px 2px 5px;	
			font-weight: 700;
		}
	}
	.last-update {
		font-size: 75%;
		margin: 3px 0;
	}

	.bullets {
		div {
			display: inline-block;
		}
		ul {
			margin: 0 auto;
		}
		li {
			margin-left: 15px;
			text-align: left;
			list-style-type: disc;
			/* white-space: nowrap; */
			line-height: 175%;
		}
	}

	.chartdiv {
		padding: 0;
		position: relative;
		width: 100%;
		height: 490px;
		@media (max-width: @screen-xs) {
			top: 0px;
			left: 0px;
			/* width: 250px; */
			height: 300px;
		}	
		.click_listing {
			margin-top: 4px;
		}		
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.chartdivRegion {
		margin: 10px auto -75px;
		@media (max-width: @screen-xs) {
			margin: -10px auto -50px;
		}			
	}
	.chartdivReligion {
		margin: -30px auto -75px;
		@media (max-width: @screen-xs) {
			margin: -20px auto -50px;
		}			
	}

	.video-frontier {
	  	margin: 0 auto;
	  	padding: 0;		
	  	font-size: 110%;
	 	max-width: 710px;
	 	@media (min-width: @screen-lg) {
			max-width: 500px;
		}	
	}

	.fpg-apps {
		font-weight: bold;
		font-size: 110%;
		margin: 10px 0 25px 0;		
		.app-container {
			display: flex; 
			text-align: right;
		 	@media (min-width: @screen-lg) {
				max-width: 75%;
			}			
		}
		.app-col-one {
			 flex: 1; 
			 margin-right: 15px;
		}
		.app-col-two {
			display: flex; 
			flex: 1; 
			flex-direction: column;
			justify-content: center; 
			text-align: center;
		}
		.app-badge-container {
			margin: 10px 0px;
			a {
				font-weight: bold;
			}
		}
		.app-badge {
			max-width: 150px;
		}	
	}

	.iframe-container{
	  position: relative;
	  padding-bottom: 56.25%; 
	  height: 0;
	  margin-bottom: 10px;
	}
	.iframe-container iframe{
	  position: absolute;
	  top:0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	}

	.show-xs {
		padding: 0px;
		margin: 0px;
	}
	.fpg-credits {
		margin: 15px auto;
		font-family: @font-family-sans-serif;
		font-size: 90%;
		/* font-weight: bold; */
	}
	.stats .col {
		min-height: 100px;
		@media (max-width: @screen-xs) {
			min-height: 75px;
		}	
	}
	.fpg-tableau {
		max-height: 850px;
		margin-top: 10px;
		@media (max-width: @screen-xs) {
			margin-top: 1px;
			max-height: 1200px; 
		}
	}
	.tableau-notes{
		font-size: .85em;
		padding-top: 10px;
		/* font-weight: bold; */
	}
}
.hidden-xs, .hidden-sm {
	/* display: inline !important; REMOVED 07/05/22 may cause problems elsewhere */
	@media (max-width: @screen-xs) {
		display: none !important;
	}	
}
.podcast-links {
	@media (max-width: @screen-xs) {
		padding-left: 25px;
	}		
	padding-right: 0px;
	p {
		line-height: 225%;
		font-size: 105%;
	}
}
.go-top {
	float: right; 
	font-size: 150%; 
	padding: 10px 8px 0 0; 
	text-decoration: none;
	a {
		color: black;
	}
}
.container {
	padding-left: 15px;
	padding-right: 15px;
	@media (max-width: @screen-sm) {
		padding-left: 10px;
		padding-right: 10px;
	}	
}
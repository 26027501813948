// Importing Variables
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.getinvolved {
	p {
		margin: 0 0 1%;
	}
	.content {
		@media (min-width: @screen-lg) {
			margin-left: 8%;
		}
		h2 {
			@media (min-width: @screen-lg) {
				margin-left: 0% !important;
			}
		}	
	}
	.steps {
		h3 {
			font-size: 125%;
		}
		p {
			text-align: center;
			margin: auto;
			max-width: 70%;
		}
	}
	.donate {
		h2 {
			@media (max-width: @screen-sm) {
				font-size: 175% !important;
			}
		}
		h4 {
			margin: 10px 0;
			@media (max-width: @screen-sm) {
				font-size: 130% !important;
			}		
		}
	}

	.pgic-pgac {
		h2 {
			@media (max-width: @screen-sm) {
				font-size: 175% !important;
			}
		}
		h4 {
			margin: 10px 0;
			@media (max-width: @screen-sm) {
				font-size: 130% !important;
			}		
		}
		p {
			@media (max-width: @screen-sm) {		
				line-height: 175%;
			}	
		}
		.bullets-normal {
			ul li, ol li {
				@media screen and (max-width: @screen-xs-max) {
					font-size: 115%;
					line-height: 175%;
				}
			}
		}
	}

	.details {	
		p {
			line-height: 200%;
		}
		blockquote {
			p {
				line-height: 150%;
			}
		}
		h4 {
			width: 100%;
			text-align: center;
			margin-bottom: 5px;
		}
		table {			
			font-size: 115%;
			td {
				padding-top: 5px;
				padding-bottom: 5px;
			}
			td:first-child {
				text-transform: uppercase;
				font-weight: bold;
				padding-right: 20px;
				@media (max-width: @screen-xs) {
					padding-right: 10px;
				}
			}
		}
		.btn {
			font-family: @font-family-sans-serif;
			font-size: 120%;
		}
		.bullets-detail {
				padding-left: 0%;
		}
	}

	h6 {
		margin: 3% 0 1% 0%;
		line-height: 1.5;
		display: inline;
		@media (min-width: @screen-lg) {
			margin: 3% 0 1% 8%;
			font-size: 150%;
		}
		@media (max-width: @screen-md) {
			font-size: 140%;
		}
		@media (max-width: @screen-sm) {
			font-size: 130%;
		}
		@media (max-width: @screen-xs) {
			font-size: 115%;
		}
	}
	bodytext {
		font-size:115%;
		font-weight:400;
		margin: 0 0 4%;
		line-height: 170%;
	}
	.center {
		text-align: center;
		margin: 0 auto;
		padding: 0;
	}

	.vision-ideas {
		display: inline;
		padding: 12px 0 8px 0;
		vertical-align: middle;
	}

	.vision-ideas-btn {
		display: inline;
		vertical-align: middle;
		.btn {
			padding: 3px 6px !important;
			font-size: 125%;
			margin-left: 7px;
			margin-bottom: 2%;
			@media (min-width: @screen-lg) {
				margin-bottom: 12px;
			}
			@media (max-width: @screen-sm) {
				font-size: 100%;
				padding: 2px 4px !important;
			}
		}
	}

	.text {
		font-size: 100%;
		margin: 0 0 0 0;
		@media (min-width: @screen-lg) {
			margin: 0 0 0 12%;
		}
	}

	.img_icon_size {
		max-width: 35px;
		margin-right: 8px;
	}
	.img_thumb_size	{
		width: 15px;
		height: 20px;
		vertical-align: bottom;
		@media screen and (max-width: @screen-xs-max) {
			margin: 0 .5% 0 .5%;
			width: 5%;
			height: 6%;
		}
	}
	.video-container {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 0px;
		height: 0;
		overflow: hidden;
    }

 	.video-container iframe,
	.video-container object,
	.video-container embed {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    padding: 0% .5% 1% .5%;
	}
	.google_map {
		max-width: 900px;
		max-height: 400px;
		margin-bottom: 10px !important;
	}
	.map_caption {
		color: @gray-dark;
		font-size: 115%;
		@media (max-width: @screen-xs) {
			font-size: 100%;
		}
		font-weight: normal;
		font-style: italic;
	}

	// Panels
	// --------------------------------------------------


	// Base class
	.panel {
		margin-bottom: @line-height-computed;
		background-color: transparent;
		box-shadow: none;
		border: none;
	}

	// Panel contents
	.panel-body {
		padding: 0px 0px 0px 0px;
		border: none;
		.clearfix();
	}

	// Collapsable panels (aka, accordion)
	.panel-group {
		margin: 2% 0 1% 0;
		.panel {
		  margin-bottom: 0;
		  margin-left: 0%;
		  box-shadow: none;
		  overflow: hidden; // crop contents when collapsed
		  + .panel {
			margin-top: 5px;
		  }
		}

		.panel-heading {
		  background-color: transparent;
		  box-shadow: none;
		  padding: 0 0 5px 0;
			  a, a:hover, a:focus {
				  text-decoration: none !important;
			  }
		}

		.panel-heading .accordion-toggle:after {
			/* symbol for "opening" panels */
			font-family: 'Glyphicons Halflings';
			content: "\e114";
			padding: 0 0 0 5px;
		}
		.panel-heading .accordion-toggle.collapsed:after {
			/* symbol for "collapsed" panels */
			content: "\e080";
		}
		.panel-body {
			border: none !important;
		}
	}

	//UPGOTD feed
	.feed {
		.upgotd_box {
			@media screen and (min-width: @screen-lg) {
					padding-left: 6%;
					}

			@media (max-width: @screen-md) {
					padding: 0 0 6% 10%;

					}
		}

		p {
			font-size:115%;
			font-weight:400;
			margin: 0 0 0 0;
			line-height: 175%;
		}

		h4 {
			margin-left: 0;
		}
	}

	//Pray

	.pray_index {

		.line {
			border-top: 2px solid @gray-light;
			margin-left: 10%;
			}

		.verse {
			margin: 5% 0 0% 7%;
			line-height: 150%;
			font-style: italic;
			font-size: 115%;
			text-align: center;
			@media screen and  (max-width: @screen-sm) {
				margin: 2% 0 0% 0;
				font-size: 90%;
			}
		}

		.pray {
			height: 110%;
			width: 110%;
			margin-left: 7%;
			border-right: 2px solid @gray-light;
			@media screen and (max-width: @screen-md) {
				margin-left: 8%;
			}
			@media screen and (max-width: @screen-sm) {
				margin-left: 19%;
			}
		}

		.sub_text {
			margin-left: 18%;
			@media screen and (max-width: @screen-md) {
				margin-left: 14%;
			}
		}
	}

	// Prayer ideas

	.prayer_ideas_padding {
		padding: 0 10%;
	}
	.prayer_ideas_spacing {
		@media screen and (max-width: @screen-md) {
			margin-bottom: 5%;
		}
	}
	.prayer_ideas_page {
		line-height: 170%;
		font-size: 105%;
		p {
			margin: 0 0 4% 0%;
		}
		ul {
			margin-left: 30%;
			list-style-type:disc;
			line-height: 175%;
			font-size: 115%;
		}
		@media screen and (min-width: @screen-lg) {
			margin-left: 8%;
		}
		img {
			margin-top: 10px;
		}
		h2 {
			margin-left: 0 !important;
		}
		h3 {
			color: @red2;
			font-size:140%;
			@media (max-width: @screen-xs) {
				font-size: 115%;
			}
		}
		.img_left {
			max-width: 265px;
			float: left;
			margin-right: 3%;
			@media (max-width: @screen-xs) {
				max-width: 240px;
				float: none;
				margin: 10px auto;
				text-align: center;
			}
		}
		.img_right {
			max-width: 265px;
			float: right;
			margin-left: 3%;
			@media (max-width: @screen-xs) {
				max-width: 240px;
				float: none;
				margin: 10px auto;
				text-align: center;
			}
		}
		.challenge_img {
			margin: 10px auto;
			display: block;
			max-width: 300px;
			@media (max-width: @screen-xs) {
				max-width: 200px;
			}
		}
	}
	.prayer_ideas_list {
		margin-top: 35px;
		img {
			max-width: 50px;
			margin: 0 10px 15px 0;
		}
		h3 {
			color: @red2;
			font-size:140%;
			@media (max-width: @screen-xs) {
				font-size: 125%;
			}
		}
		p { 
			padding-left: 15px;
			@media (max-width: @screen-xs) {
				padding-left: 0px;
			}
		}
	}

	.website-page {
		font-size: 100%;
		margin-bottom: 20px;
		hr {
			border-color: @red2;
			background-color: @red2;
			height: 2px;
			margin: 20px 0 10px;
			width: 50%;
		}
		p {
			text-align: left;
			max-width: 750px;
		}
	}

	.about-header {
		width: 900px;
		@media screen and (max-width: @screen-md-max) {
			width: 700px;
		}
	}

	a:focus {
		text-decoration: none;
		outline: none;
	}

	.caption {
		max-width: 250px;
	}
	.website-subtitle {
		font-size: 65%;
		@media (max-width: @screen-xs) {
			font-size: 75%;
		}
	}

} // close get involved

.logos-perspective {
	p {
		font-weight: 900;
	}
	img {
		max-width: 140px;
	}
}

.connections {
	div {
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  height: 185px;
	}
}
dl {
	a {
		font-weight: 900;
	}
}

.overlay-container {
    position: relative;
    width: 100%;
    /* max-width: 300px; */
    max-width: var(--img-width);
    margin: 15px auto 15px;
}
.overlay-img {
    display: block;
    width: 100%;
    height: var(--img-height);
    /* height: auto; */
}
.overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    /* font-size: 24px; */
    font-size: var(--font-size);
    font-weight: 900;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--font-color); 
    /* background-color: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background */
    padding: 10px; /* Optional: padding around the text */
    box-sizing: border-box;
}
.volunteer {
	h4 {
		margin: 25px auto;
		font-size: 24px;
		@media (max-width: @screen-xs) {
			font-size: 20px;
		}
	}
}
.bullets-volunteer {
	ul, ol {
		text-align: left;
		width: 100%;
		margin-bottom: 3%;
		margin-left: 10px !important;
		li {
			line-height: 170% !important;
			padding-bottom: 5px;
			padding-right: 5px;
			@media (max-width: @screen-sm) {
				font-size: 115% !important;
				padding-left: 0;
			}
			ul {
				@media (max-width: @screen-sm) {
					font-size: 85% !important;
					padding-left: 10px;
				}
				padding-left: 25px;
			}
		}
	}
}
.bullets-container {
	margin: 0 auto; 
	padding: 0 0 0 10%;
}

.table-volunteer {
	font-size: 115%;
	td {
		text-align: center;
		padding: 5px 15px;
	}
}
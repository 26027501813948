@charset "UTF-8";
/* CSS RESET */
/* ------------------------------------------------------------------------------------------*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;font-weight:inherit;font-style:inherit;font-size:100.01%;font-family:inherit;vertical-align:baseline}:focus{outline:0}body{line-height:1;color:black;background:white;margin:0px;}
ol,ul{list-style:none}table{border-collapse:separate;border-spacing:0}caption,th,td{text-align:left;font-weight:normal}
blockquote:before,blockquote:after,q:before,q:after{content:""}blockquote,q{quotes: none;} blockquote:before, blockquote:after, q:before, q:after {content: ''; content: none;}
em, i {font-style: italic;}strong, b {font-weight: bold;} article, aside, figure, footer, header, hgroup, nav, section {display: block;} 
img, object, embed {max-width: 100%;} html {overflow-y: scroll;} 
a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}
del {text-decoration: line-through;}
abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}
table {border-collapse: collapse; border-spacing: 0;} th {font-weight: bold; vertical-align: bottom;} td {font-weight: normal; vertical-align: top;}
hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}
input, select {vertical-align: middle;}
pre { 	white-space: pre; /* CSS2 */ 	white-space: pre-wrap; /* CSS 2.1 */ 	white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */ 	word-wrap: break-word; /* IE */ }
input[type="radio"] {vertical-align: text-bottom;} input[type="checkbox"] {vertical-align: bottom; *vertical-align: baseline;} .ie6 input {vertical-align: text-bottom;}
select, input, textarea {font: 99% sans-serif;}
/* Accessible focus treatment 	people.opera.com/patrickl/experiments/keyboard/test */ a:hover, a:active {outline: none;}
small {font-size: 85%;}  strong, th {font-weight: bold;}  td, td img {vertical-align: top;}
.clickable, label,  input[type=button],  input[type=submit],  button {cursor: pointer;}
/* Webkit browsers add a 2px margin outside the chrome of form elements */ 
/* make buttons play nice in IE */ button {width: auto; overflow: visible;}button, input, select, textarea {margin: 0;}
/* let's clear some floats */ .clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }   .clearfix:after { clear: both; }   .clearfix { zoom: 1; clear:both; }  

/* Other Useful classes for sitewide use */

.center {
	text-align:center;
}
.hide {
	display:none;
} 
.left {
	float:left;
} 
.right {
	float:right;
}
input {
    -webkit-appearance: none;
    border-radius: 0;
}
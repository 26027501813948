// Importing Variables
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.resources {
	.left {
		text-align: left;
	}
	.center {
		text-align: center;
	}

	#praying-widget #praying-action-btn {
		padding: 6px 8px !important;
    	margin: 0 !important;
	}

	.gray {
		background-color: @gray-lighter;
	}
	h5 {
		font-size: 125%;
	}

	//
	// Tables
	// --------------------------------------------------

	.table {
		font-size: 90%;
		margin: 2% auto 2%;
		width: 90%;
		box-shadow: 10px 10px 5px @gray-dark;
		> thead {
			vertical-align:bottom;
		}
		> td {
			padding-bottom:15px;
			> ul {
				list-style-type:disc;
				margin: 0;
				padding-left: 9%;
			}
		}
		@media screen and (min-width: @screen-md-max) {
			margin-left: 8%;
		}
	}
	.table-center {
	   th, td {
		  text-align: center;
	   }
	}

	.table-bordered {
	  border: 1px solid @black1;
	  > thead,
	  > tbody,
	  > tfoot {
		> tr {
		  > th,
		  > td {
			border: 1px solid @black1;
		  }
		}
	  }
	  > tbody {
		> tr {
		  > td {
			padding-bottom: 2%;
		  }
		}
	  }
	  > thead {
		> tr {
		  > th,
		  > td {
			background-color: @gray-light;
			border-bottom-width: 2px;
		  }
		}
	  }
	}

	.bs-example {
		font-size: 90%;
		line-height: 125%;
		table td {
			vertical-align: middle;
			padding: 5px 5px !important;
			border-bottom: 1px solid @gray;
		}
		table td:first-child, table td:nth-child(2) {
			text-align: center;
			font-weight: bold;
		}
		table th {
			white-space: nowrap;
			background-color: @gray-lighter;
			border-bottom: 1px solid @black1 !important;
			border-top: 1px solid @black1 !important;
		}
	}

	//
	// Datasets Table
	//

	.datasets-table {
		margin-left: 10%;
		@media (max-width: @screen-xs) {
			margin-left: 2%;
		}
		td {
			color: @black1;
			padding: 0px 20px 12px 0;
			font-size: 115%;
			@media (max-width: @screen-sm) {
				font-size: 100%;
			}
			@media (max-width: @screen-xs) {
				font-size: 90%;
			}
		}
		td:first-child {
			padding-right: 10px;
		}
		.glyphicon {
			color: @link-color;
		}
	}

	// Go2020

	.go2020 {
		p {
			font-size: 125%;
			font-weight: bold;
		}
		.go2020-table {
			font-size: 115%;
			margin: 10px auto;
			padding: 0 10px;
			td, th {
				padding: 8px 10px;
				text-align: center;
				vertical-align: middle;
				width: 20%;
				white-space: nowrap;
			}
			td:first-child, th:first-child {
				width: 10%;
			}
			th {
				background-color: @blue2;
				color: @white1;
			}
			@media (max-width: @screen-xs) {
				margin: 10px 0px 10px -20px;
				font-size: 100%;
				td, th {
					padding: 10px 5px;
					font-size: 90%
				}
				img {
					max-width: 40px;
				}
			}
		}
	}

	//
	// Panels
	// --------------------------------------------------


	// Base class
	.panel {
		margin-bottom: @line-height-computed;
		background-color: transparent;
		box-shadow: none;
		border: none;
	}

	// Panel contents
	.panel-body {
		padding: 5px 0px 0px 0px;
		.clearfix();
	}

	// Collapsable panels (aka, accordion)
	.panel-group {
	  // Tighten up margin so it's only between panels

		.panel {
			margin-bottom: 0;
			box-shadow: none;
			overflow: hidden; // crop contents when collapsed
			+ .panel {
				margin-top: 3.5%;
			}
			.panel-heading {
				padding-left: 0%;
				font-size: 110%;
				a, a:hover, a:focus {
					text-decoration: none !important;
				}
			}
			.panel-heading .accordion-toggle:after {
				/* symbol for "opening" panels */
				font-family: 'Glyphicons Halflings';
				content: "\e114";
				padding: 0 0 0 0;
				color: @link-color;
			}
			.panel-heading .accordion-toggle.collapsed:after {
				/* symbol for "collapsed" panels */
				content: "\e080";
			}
			.panel-body {
				border: none !important;
				margin: 0;
			}
			.hover-red {
				text-decoration-color: @red2;
			}
		 }
	  }

	.tab-content a {
		margin-bottom: 0;
		& > li > a {
			margin-bottom: 0;
		}
	}

	//
	// Various Page Specific
	// --------------------------------------------------
	.articles {
		h4 {
			color: @black;
		}
	}
	.articles-list {
		div {
			margin: 30px 0%;
		}
		a {
			text-decoration: none;
		}
	}
	.articles-more {
		color: @red2;
		font-style: italic;
		font-weight:bold;
		font-size: 150%;
		@media (max-width:@screen-xs) {
			font-size: 125%;
		}
		a, a:hover {
			color: @red2;
			text-decoration-color: @red2;
		}
	}
	.pdf-list {
		div {
			margin:1.5% 0 4% 7%;
		}
	}
	.books-list {
		img {
			padding: 10px 5px;
			max-height: 150px;
		}
		line-height: 200%;
		 ul {
			list-style-type:disc;
			margin: 0;
			padding: 1% 0 1% 9%;
			@media (max-width:@screen-xs) {
				padding: 1% 0 1% 6%;
			}				
		}
		li {
			font-size: 115%;
			@media (max-width:@screen-xs) {
				font-size: 100%;
			}			
			padding-bottom: 5px;
		}
	}
	.caption {
		padding: 2% 0;
		font-size: 90%;
		line-height: 140%;
		@media (max-width:@screen-xs) {
			font-size: 80%;
		}
	}
	.text {
		font-size: 100%;
		margin: 0 0 4%;
		line-height: 140%;
		padding: 0 0 0 8%

	}
	.media-page {
		text-align: center;
		p {
			font-size: 115%;
			line-height: 155%;
			margin: 4% 10%;
			@media (max-width: @screen-xs) {
				margin: 4%;
			}			
		}
		img {
			max-width: 375x;
			max-height: 250px;
			display: inline;
			margin-bottom: 10px;
			@media (max-width: @screen-xs) {
				/* max-width: 250px; */
				max-height: 185px;
			}
		}
	}
	.prayer-ideas-page {
		text-align: center;
		p {
			font-size: 115%;
			line-height: 155%;
			margin: 4% 5%;
			@media (max-width: @screen-xs) {
				font-size: 100%;
			}
		}
		img {
			max-width: 250px;
			max-height: 200px;
			display: inline;
			@media (max-width: @screen-xs) {
				max-width: 250px;
				max-height: 185px;
			}
		}
	}
	.datasets-page {
		h3 {
			padding-bottom: 10px;
		}
		p {
			font-size: 115%;
			line-height: 175%;
			/* margin-left: 3%; removed 03/27/23 */
			margin-bottom: 0;
		}
		dd {
			margin-left: 4%
		}
		img {
			margin-bottom: 10px;
			max-width: 300px;
			max-height: 200px;
		}
	}
	.gc-maps {
		text-align: center;
		h3 {
			margin: 0;
			padding: 10px 0 15px;
		}
		p {
			margin: 1% 10% 4% 10%;
			@media  (max-width: @screen-xs) {
				margin: 2% 5% 5% 5%;
				padding-bottom: 5px;
			}
			@media  (min-width: @screen-lg) {
				margin: 1% 14% 2% 14%;
			}
		}
		.row {
			@media  (min-width: @screen-lg) {
				margin-bottom: 15px;
			}
		}
	}
	.frontier-peoples-map {
		max-width: 400px;
	}
	.retry {
		.row {
			margin-bottom: 5px;
		}
		a {
			text-decoration: none;
		}
		h1 {
			font-size: 500%;
		}
		img {
			max-width: 80px;
		}
	}
	.handouts {
		text-align: center;
		display: block ;
		img {
			/* max-width: 70%; */
			max-height: 250px;
			padding: 0;
			@media  (max-width: @screen-md) {
				/* width: 50%; */
			}
		}
		ul {
			margin-bottom: 3%;
			margin-left: 2%;
		}
		li {
			white-space: nowrap;
		}
		.div-spacing {
			padding-top: 15px !important;
			padding-bottom: 30px !important;
			@media  (max-width: @screen-xs) {
				padding-top: 10px !important;
			}
		}
		h4 {
			margin-top: 10px;
			@media  (max-width: @screen-xs) {
				margin-bottom: 10px !important;
			}
		}
		h5 {
			font-size: 125%;
		}
		.poster-sub {
			font-size: 100%;
			line-height: 175%;
			font-weight: bold;
			margin: 10px auto -10px;
		}
		.booklets-intro {
			font-size: 115%;
			line-height: 170%;
			padding-bottom: 15px;
			@media  (min-width: @screen-md) {
				margin: auto;
				width: 75%;
			}
		}		
	}

	.gc-videos {
		text-align: center;
		h3 {
			margin: 0 0 10px 0;
			font-size: 150%;
		}
		h4 {
			font-size: 130%;
			line-height: 140%;
		}
	}

	.video-container {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 0px;
		margin-top: 0px;
		margin-bottom: 6%;
		height: 0;
		overflow: hidden;
	}
	.video-container iframe,
	.video-container object,
	.video-container embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0% .5% 1% .5%;

	}

	.prayer_img_sm {
		max-width: 125px;
		max-height: 125px !important;
	}

	//Tabs
	.tab_section {
		line-height: 180%;
		font-size: 100%;
		p {
			margin-left: 8%;
			// margin-right: 20%;
			@media (max-width: @screen-md) {
				margin-left: 6%;
				margin-right: 2%;
			}
		}

		.tabs {
			border-width: 3px;
			margin-right:auto;
			padding-right: 0%;
			padding-left: 5%;
			margin-left: auto;
			@media (max-width: @screen-md) {
				margin-right: 10%;
				border-width: 0px;
				padding-left: 0;
			}
			@media (max-width: @screen-sm) {
				margin-right: auto;
				margin-left: auto;
				width: 100%;
			}
		}

    } // close tab section

	// Prayer Cards
	.p_cards {
		p {
			margin-left: 10%;
			margin-right: 10%;
			margin-bottom: 0;
			font-size: 115%;
			@media (max-width: @screen-md) {
				margin-left: 6%;
				margin-right: 2%;
			}
		}
		img {
			margin-bottom: 2%;
		}
		h4 {
			margin: 0 0 15px 0;
			font-family: inherit;
			font-size: 110%;
			font-weight: 600;
			@media (max-width: @screen-lg) {
				font-size: 100%;
				font-weight: 600;
			}
			@media (max-width: @screen-md) {
				font-size: 125%;
				font-weight: 600;
			}
		}
		h6 {
			font-weight:normal;
			color: @orange1;
			font-size:145%;
			padding:0 0 .2% 0% 0%;
			margin-bottom: 1%;
			margin-top: 1%;
			margin-left: 12%;
			@media (max-width: @screen-lg) {
				margin-left: 5%;
			}
		}
		.modal-dialog {
			max-width: 475px;
			margin-top: 250px;
		}
		.modal-header {
			background-color: @blue2;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		.modal-title {
			font-family: inherit;
			font-size: 125%;
			color: white;
			font-weight: bold;
		}
		.modal-body {
			font-family: inherit;
			font-size: 115%;
			line-height: 170%;
		}
		.close {
			right: 0;
			/* margin: -5px -20px 0 0; */ /* Close button inside */
			margin: -29px -34px 0 0;
			opacity: unset;
			&:hover,
			&:focus {
				cursor: pointer;
			}
		}
	}

// Bulletins
.bulletins {
	img {
		text-align: center;
		padding: 0% 0 10% 0;
	}
	h5 {
		max-width: 100% !important;
	}
}

// Children
.children {
	h2 {
		@media (max-width: @screen-sm) {
			font-size: 150%;
			margin-bottom: 20px !important;
		}
	}
	h4 {
		@media (max-width: @screen-xs) {
			font-size: 150%;
			padding-top: 10px;
			padding-bottom: 10px;
			margin: 0;
		}
		@media (max-width: @screen-sm) {
			font-size: 125%;
		}
	}
		p {
			width: 95%;
			@media (max-width: @screen-xs) {
				width: 100%;
			}
		}
	.row {
		div {
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
	img {
		padding-bottom: 10px;
		@media (max-width:@screen-xs) {
			max-width: 250px;
			max-height: 300px;

		}
	}
}

// Apps
.apps {
	.hidden-xs {
		display: inline !important;
		@media (max-width: @screen-xs) {
			display: none !important;
		}
	}
	h4 {
		white-space: nowrap;
		padding-top: 10px;
		margin-bottom: 10px;
	}
	h6 {
		font-size: 125%;
		display: inline;
		@media (max-width: @screen-xs) {
			font-size: 110%;
		}
	}
	p {
		font-size: 115%;
		line-height: 165%;
		margin: auto;
		@media (max-width: @screen-lg) {
			max-width: 50%;
		}
		@media (max-width: @screen-md) {
			max-width: 70%;
		}
		@media (max-width: @screen-xs) {
			max-width: 90%;
			font-size: 110%;
			line-height: 160%;
			margin-bottom: 15px;
		}
	}
	ul {
		list-style-type: disc;
		margin-left: 20px;
		font-size: 115%;
		line-height: 175%;
		@media (max-width: @screen-sm) {
			font-size: 95%;
			line-height: 150%;
			margin-left: 25px;
		}
		li {
			padding-bottom: 7px;
		}
	}
	hr {
		border-color: @red2;
		background-color: @red2;
		height: 1px;
		margin: 15px auto 10px;
		width: 75%;
	}

	.table-responsive {
		margin: 15px auto 15px;
		border: none;
		img {
			max-width: 125px;
			margin: 0;
			padding: 0;
			@media (max-width:@screen-xs) {
				max-width: 100px;
			}
		}		
		td {
			text-align: center;
			vertical-align: middle;
			font-weight: bold;
			font-size: 115%;			
			padding: 7px 10px;
			@media (max-width:@screen-xs) {
				font-size: 100%;
				padding: 5px 4px;
			}
		}
		td:last-child {
			padding-right: 0;
		}
	}
	.first-right {
		td:first-child {
			text-align: right;
		}	
		}
	.panel-heading {
		margin-left: 0%;
	}

	.shots {
		margin: 0;
	}
	img {
		@media (max-width:@screen-lg) {
			max-width: 225px;
			max-height: 235px;
		}
		@media (max-width:@screen-md) {
			max-width: 225px;
		}
		@media (max-width:@screen-sm) {
			max-width: 170px;
			/* max-height: 170px; */
		}
		@media (max-width:@screen-xs) {
			max-width: 150px;
			max-height: 250px;
		}
		margin: 10px auto;
		padding: 0;
	}
} // close apps


//Help
.definitions {
	font-size: 110%;
	line-height: 170%;
	p {
		font-size: 100%;
		@media (max-width: @screen-xs) {
			font-size: 90%;
		}
		margin: 0 0 2% 5%;
	}
	.bullets-normal {
		ul {
			margin-bottom: 15px;
		}
		li {
			font-size: 100%;
		}
	}
	div.col-xs-12 {
		@media (max-width: @screen-xs) {
			padding: 0 !important;
		}
	}

	.table-progress-definitions {
		line-height: 130%;
		font-size: 93%;
		table {
			width: 99%;
			margin: 0 !important;
			padding: 0;
			td {
				line-height: 160%;
			}
		}
	}

	// Panel contents
	.panel-body {
		padding: 0px 0px 0px 0px;
		border: none;
		.clearfix();
	}

	// Collapsable panels (aka, accordion)
	.panel-group {
		margin: 0;
		.panel {
			margin-bottom: 3px;
			margin-left: 5%;
  			@media (max-width: @screen-xs) {
				margin-left: 0 !important;
			}
			box-shadow: none;
			overflow: hidden; // crop contents when collapsed
			+ .panel {
				margin-top: 7px;
			}
		}

		.panel-heading {
			background-color: transparent;
			box-shadow: none;
			font-family: @font-family-sans-serif;
			font-size: 120%;
			margin-bottom: 5px;
			margin-right: 0px;
			@media (max-width: @screen-xs) {
		  		font-size: 90%;
			}
			padding: 0 0 0 0;
		}

		.panel-heading .accordion-toggle:after {
			/* symbol for "opening" panels */
			font-family: 'Glyphicons Halflings';
			content: "\e114";
			font-size: 85%;
			padding: 0 0 0 5px;
			/* vertical-align: bottom; */
		}
		.panel-heading .accordion-toggle.collapsed:after {
			/* symbol for "collapsed" panels */
			content: "\e080";
			padding: 0 0 0 2px;
		}
		.panel-body {
			border: none !important;
			margin-left: 4%;
  			@media (max-width: @screen-xs) {
				margin-left: 0 !important;
				font-size: 85%;
			}
			p {
				margin-left: 0;
			}
		}
	}
}

.sitemap {
	.table {
		font-size: 94%;
		margin: 1% auto 1%;
		width: 100%;
		box-shadow: none;

		> thead, thead a {
			vertical-align:bottom;
			color: @red2;
			font-size: 110%;
		}
		> td {
			padding-bottom:5px;
			> ul {
				list-style-type:disc;
				margin: 0;
				padding-left: 9%;
			}
			.indent {
				margin-left: 25%;
			}
		}
		@media screen and (min-width: @screen-md-max) {
			margin-left: 8%;
		}
	}
}

h3 {
	margin: 12px 0 2px;
}

//PowerPoints

	.ppt_iframe  {
		width: 300%;
	}
}

// 1040 Window Article
.window-article {
	font-size:100%;

	p {
		font-size:115%;
		font-weight:400;
		margin: 0 0 4%;
		line-height: 170%;
	}
	@media screen and (max-width: @screen-xs-max) {
		font-size: 105%;
	}
	blockquote {
		font-size: 175%;
	}
	.country-titles {
		color: @red2;
		font-size: 125%;
		font-weight: bold;
 	}
	.window_list {
		font-size: 115%;
		line-height: 175%;
	}
}

.bullets-normal {
	ul li, ol li {
		@media screen and (max-width: @screen-xs-max) {
			font-size: 105%;
			line-height: 150%;
		}
	}
}


// Prayer Cards
.prayer_card_border {
	border: 1px solid @gray-dark;
}


// Prayer Calendars

.prayer_calendars {
	/* font-size: 115%; */
	line-height: 190%;
	@media (max-width: @screen-sm) {
		font-size: 95%;
	}
	img {
		padding: 0 0 15px 0;
		@media (max-width: @screen-xs) {
			max-width: 250px;
		}
		margin: 0;
	}
}

// Prayer Guides

.gpd-header {
	text-align: left;
	h4 {
		 a {
			color: @gray-darker;
		}
	}
	h5 {
		font-size: 115%;
		margin: 0 auto 3px;
		max-width: 100%;
		color: #0033BB;
	}
	img {
		max-width: 115px;
		max-height: 125px;
		@media screen and (max-width: @screen-md-max) {
			max-width: 175px;
			max-height: 175px;
		}
		@media screen and (max-width: @screen-xs-max) {
			max-width: 150px;
			max-height: 150px;
		}
	}
	.social {
		max-width: 45px;
	}
}

.gpd-archive {
	text-align: center;
	img {
		max-width: 130px;
	}
}

.container_prayer {
	@media (max-width: @screen-xs) {
		padding: 0px !important;
	}
}
.prayer_guides {
	h2 {
		@media (max-width: @screen-xs) {
			font-size: 170%;
		}
	}
	h4 {
		@media (max-width: @screen-xs) {
			font-size: 150%;
		}
	}
	h5 {
		padding-top: 15px;
		@media (max-width: @screen-xs) {
			font-size: 140%;
			margin-left: 5%;
			margin-right: 5%;
		}
	}
	h6 {
		line-height: 200%;
	}
	p {
		font-size: 115%;
	}
	a {
		text-align: center;
	}
	img {
		max-width: 300px;
		max-height: 225px;
		@media (max-width: @screen-md) {
			padding-bottom: 5px;
			max-width: 300px;
		}
		@media (max-width: @screen-sm) {
			padding-bottom: 15px;
			max-width: 300px;
		}
		@media (max-width: @screen-xs) {
			padding-bottom: 10px;
			max-width: 225px;
		}
	}
	.row {
		margin-bottom: 25px;
		@media screen and (max-width: @screen-md-max) {
			margin-bottom: 0;
		}
	}
	.calendar_note {
		margin-top: 10px;
		font-size: 90%;
		@media (max-width: @screen-xs) {
			/* margin: 5px 0 10px 0; */
			font-size: 95%;
		}
	}
	table {
		width: 100%;
		tr {
			padding: 0;
		}
		td {
			padding: 0 0 0 15px;

			white-space: nowrap;
		}
		td:first-child {
			width: 45%;
			text-align: right;
			padding: 0 15px 0 25px;
		}
		.td-all-peoples {
			text-align: center !important; 
		}
	}
}

.prayer_guides_more {
	font-size: 115%;
	a {
		line-height: 200%;
		@media (max-width: @screen-md) {
			font-size: 100%;
			line-height: 170%;
		}
	}
	select {
		margin: 5px 0 15px 0;
		font-size: 95%;
		line-height: 95%;
		@media (max-width: @screen-sm) {
			font-size: 100%;
			line-height: 70%;
		}
	}
}

.issues_past {
	background-color: @gray-lighter;
}
.issues_future {
	background-color: #e6f0ff;
}
.issues {
	white-space: nowrap;
	padding-bottom: 15px;
	@media (max-width: @screen-xs) {
		img {
			max-width: 125px;
		}
		padding-bottom: 5px;
	}
}
.data-sources {
	p {
		font-size: 115%;
		line-height: 170%;
	}
	h4 {
		margin: 0 0 1% 0;
		padding: 0;
	}
	ul {
		list-style-type:disc;
		padding: 0 0 5% 8%;
		line-height: 170%;
		font-size: 115%;
	}
	@media (min-width: @screen-lg) {
		/* margin-left: 8%; */
	}
}

.churches {
	img {
		padding: 10px 10px;
	}
}
// Facebook and Unreached

.facebook {

	ol {
		list-style-type: decimal;
		margin-left: 2%;
		font-size: 115%;
		line-height: 170%;
	}
	ul {
		margin-left: 2%;
		list-style-type:disc;

	}
	li {
		margin-left: 0;
		padding: 5px 0;
	}
	ul li {
		margin-left: 2%;
	}
	.we-chose {
		/* color: @red2; */
	}
	p {
		font-size: 115%;
	}
}

.upgotd-es {
	display: inline-block;
	font-size: 115%;
	font-weight: bold;
	text-align: center;
	img {
		padding: 10px 10px 5px 20px;
	}
}

// Whats New
.whatsnew {
	white-space: nowrap;
	@media (max-width: @screen-sm) {
		img {
			max-width: 100px;
		}
	}
}

// Imporing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

// font-size: 115%;

.contact {
	.content {
		max-width: 950px;
		@media (min-width: @screen-lg) {
			margin-left: 4%;
		}
		h1 {
			margin-left: 0% !important;
		}
	}
	.dl-horizontal dt {
		font-size: 115%;
		display: inline;
		/* float: left; */
		width: auto;
		margin-left: 12%;
		@media (max-width: @screen-lg) {
			margin-left: 8%;
		}
		@media (max-width: @screen-xs) {
			margin-left: 0%;
			font-size: 100%;
			float: left;
		}
	}

	.dl-horizontal dd {
		padding: 2px 0 4px;
		margin-bottom: 0;
		margin-left: 120px;
		font-size: 115%;
		.glyphicon {
			display:inline;
			color: @red2;
			font-size: 150%;
			@media (max-width: @screen-sm) {
				font-size: 105%;
			}
		}
		@media (max-width: @screen-xs) {
			font-size: 100%;
			margin-left: 70px;
			padding: 2px 0;
		}
		@media (min-width: @screen-sm) {
			margin-left: 140px;
		}
		@media (min-width: @screen-md) {
			margin-left: 120px;
		}
		@media (min-width: @screen-lg) {
			margin-left: 150px;
		}
		.social {
			font-size: 200% !important;
			margin-right: 0 !important;
			padding: 0 5px 0 0!important;
		}
	}

	.dl-send-updates {
		dd {
			font-size: 125%;
			font-weight: bold;
			line-height: 3;
			padding-top: 0;
			margin-left: 25px;
			@media (min-width: @screen-lg) {
				margin-left: 35px;
			}
			@media (max-width: @screen-xs) {
				line-height: 2.5;
				margin-left: 0px;
			}
			a:hover {
				text-decoration: none;
				.glyphicon {
					color: @gray;
				}
				.link-text {
					text-decoration: underline;
					color: @link-hover-color;
					padding-bottom: 5px !important;
				}
			}
		}
	}

	bodytext {
		font-size:115%;
		font-weight:400;
		margin: 0 0 4%;
		line-height: 170%;
	}

	.bullets-normal {
		margin: 0;
		padding: 0 0 0 3%;
		li {
			list-style-type:disc;
			padding: 0 0 1% 0%;
			padding-left: 1%;
			line-height: 170%;
			font-size: 115%;
			@media (max-width: @screen-xs) {
				font-size: 100%;
			}
		}
		@media screen and (min-width: @screen-md-max) { // everything except blockquote gets slight padding
			ul {
				margin-left: 7%;
			}
		}
	}

	.bullets-large {
		li {
			font-size: 125%;
			line-height: 180%;
		}
	}

	.img_thumb_size	{
		width: 2.5%;
		height: 3.25%;
		vertical-align: bottom;
		@media screen and (max-width: @screen-xs-max) {
			margin: 0 .5% 0 .5%;
			width: 5%;
			height: 6%;
		}
	}

	p {
		font-size: 115%;
		line-height: 170%;
		@media (max-width: @screen-xs) {
			font-size: 100%;
		}
	}
	line-height: 175%;
	.contact_form {
		margin-left: 0%;
		margin-right: 0%;
	}
	.form-control {
		color: @gray-darker;
		max-width: 100%;
	}

	.panel {
		margin-bottom: @line-height-computed;
		background-color: transparent;
		box-shadow: none;
		border: none;
		margin: 0;
		padding: 0;
	}

	.short {
		width: 60%;
		@media screen and (max-width: @screen-lg) {
			width: 80%;
		}
		@media screen and (max-width: @screen-sm) {
			width: 100%;
		}
	}

	.note {
		font-style: italic;
		padding: 0 0 15px 0;
		@media (min-width: @screen-lg) {
			max-width: 80%;
		}
		@media (max-width: @screen-md) {
			max-width: 60%;
		}
		@media (max-width: @screen-sm) {
			max-width: 85%;
		}
		@media (max-width: @screen-xs) {
			max-width: 100%;
			font-size: 90%;
			padding-top: 15px;
		}
	}

	.form-label-note {
		font-weight: normal;
		font-style: italic;
		font-size: 95%;
	}

}
#security, #screen {
	background-color: @white1;
	margin-top: .5%;
	padding: 2% 4% 0;
}

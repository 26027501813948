// Importing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";

.unreached-widget {
	font-size: 14px;
	/* text-align: center; */
	margin: 15px auto 0;
	/* max-width: 900px; */

	h1 {
		padding: 5px 0 10px 0;
		font-size:22px;
	}

	.options_container {
		/* max-width: 410px; */
		text-align: left;
		margin-left: 5px;
		@media (max-width: @screen-xs) {
			margin-left: 5px;
		}
		@media (max-width: @screen-sm) {
			margin-left: 10px;
		}
		margin-bottom: 10px;
		form {
			width: 100%;
			text-align: center;
			/* margin-left: 10px; */
		}
		.ui-accordion-content {
			padding: 1em 1em;
		}
	}

	.accordion {
		margin: 5px 0 0 0;
	}

	.results_container {
		display:inline;
		overflow:hidden;
		padding: 0;
		margin: 0 auto;
	}

	.widget_container {
		overflow:hidden;
		text-align: center;
		max-height: 500px;
		/* margin-top: 50px; */
		#widget {
			min-height: 500px;

			iframe {
				/* height: 440px; */
				height: 100%;
				min-height: 500px;
				/* width: 370px; /* for landscape */
				width: 100%;
				overflow:hidden;
				margin:0;
				padding:0;
			}
		}
	}

	.code_container {
		/* text-align: center; */
		margin: 0 auto;
	}
	table {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	td {
		white-space: nowrap;
		padding: 7px 10px 7px 0px;
	}
	.widget_type {
		font-size: 125%;
		margin: 10px auto 15px;
	}
	.widget_steps {
		margin: 5px 0 15px 0;
		font-weight: bold;
		color: @gray-darker;
	}
	.widget_steps_2 {
		margin: 5px 0 0px 0;
		@media (max-width: @screen-sm) {
			margin-top: 0px;
		}
		font-weight: bold;
		color: @gray-darker;
	}
	.widget_steps_number {
		float: left;
		padding: 0 10px 0 0px;
		font-size: 250%;
		color: @red2;
	}
	.widget_steps_text {
		font-size: 115%;
		padding: 5px 0 0 0;
		text-align: left;
		br {
			line-height: 18px;
		}
	}
	.widget_form {
		width: 100%;
		table {
			width: 100%;
		}
		td {
			padding: 0 10px 0 0;
			margin: 0;
			vertical-align: middle;
		}
		.width_max {
			width: 100%;
		}
	}
	.form-control {
		height: 30px;
		font-size: 100%;
		font-weight: normal;
	}
	::-webkit-input-placeholder { /* WebKit browsers */
		font-size: 85%;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		font-size: 90%;
	   	opacity:  1;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		font-size: 90%;
	   	opacity:  1;
	}
	:-ms-input-placeholder { /* Internet Explorer 10+ */
		font-size: 90%;
	}
	.widget_add {
		color:@red2;
		font-weight:bold;
		font-size: 120%;
		margin: 5px 0px 9px 0px;
		width: 200px;
	}
	.widget_samples {
		padding: 0 0 5px 0;
	}
	#colorSelectors {
		td {
			padding:2px 10px 2px 0;
		}
	}
	.clipboard-wrapper {
		p {
			cursor: pointer;
			color: #770000;
			font-size: 25px;
			margin: 0;
			line-height: 50%;
			.smaller-text {
				font-size: 12px;
				font-weight: bold;
				left: 5px;
				position: relative;
				top: -4px;
				text-transform: uppercase;
			}
		}
		a {
			cursor: pointer;
		}
		margin: 0 0 10px 0;

		.popover {
			// For some reason bootstrap is showing both a popover and a tooltip.
			visibility: hidden;
		}
	}
	textarea {
		width: 90%;
		height: 150px;
		font-size: 100%;
		padding: 0;
		margin: 0;
		text-align: left;
	}
	.clear {
		clear:both;
		margin:0;
		padding:0;
	}

	.center {
		text-align: center;
	}

	.left {
		float:left;
	}

	.right {
		float:right;
	}

	input {
		font-weight:700;
		cursor:pointer;
		border:none;
		border-spacing:0;
		border-radius: 7px;
		-moz-border-radius:7px;
		-webkit-border-radius:7px;
	}

	.button {
		white-space:nowrap;
	}

	#reset {
		margin-top:10px;
	}
	select {
		margin:9px;
	}

	#cbg {
		background-color:#eee;
	}

	#chc {
		background-color:#00f;
	}

	#bbg,#fbg {
		background-color:#2870C0;
	}

	#blc,#bhc,#ffc,#flc,#fhc,#bdc,#colorSelector {
		background-color:#FFF;
	}

	#cfc,#clc {
		background-color:#000;
	}

	#bdr {
		margin-top:5px;
	}

	.radioset {
		margin: 0px 0px;
	}

	.slider {
		margin:5px 1px;
	}
	#width_slider {
		margin-bottom:10px;
	}
	#width_spinner {
		margin-top:5px;
		text-align: center;
	}
	.jq_check_label {
		margin-top:5px;
		width:150px;
	}

	.overflow {
		height: 200px;
	}
}

 // Importing Variables
@import '../variables.less';

//UPGOTD page

/*
.container {
	padding-left: 10px;
	padding-right: 10px;
}
*/

.landing {
	@media (min-width: @screen-lg) {
		margin-left: 0;
	}
	h4 {
		/* white-space: nowrap; */
		padding-top: 10px;
		margin-bottom: 10px;
		font-size: 135%;
	}
	img {
		/* margin: 10px auto; */
	}
	p {
		font-size: 115%;
		line-height: 175%;
		@media (min-width: @screen-lg) {
			max-width: 85%;
		}
		margin: 0 auto 15px;
	}
	.sub-title {
		font-size: 95%;
	}
}

h4 {
	color: @red2;
	margin-bottom: 15px;
	@media (max-width: @screen-xs) {
		font-size: 150%;
		max-width: 100%;
	}
}

.calendar-add {
	a {
		text-decoration: none;
	}
	img {
		padding-left: 20px;		
		@media (max-width: @screen-xs) {
			padding-left: 15px;
		}
	}
	font-size: 120%;
	@media (max-width: @screen-xs) {
		font-size: 100%;
	}
	font-weight: bold;
}

.shading-blue {
	background-color: #e6f0ff;
}

.shading-gray {
	background-color: @gray-lighter;
}

input, select {
	vertical-align: baseline;
	overflow: hidden;
}
label {
	/* display: inline; */
	margin-bottom: 10px;
}
.map {
	@media (max-width: @screen-lg) {
		text-align: center;
	}
}
.audio-button {
	border: none;
	background-color: transparent;
	font-size: 130%;
}

.audio-text {
	border: none;
	background-color: transparent;
	font-size: 115%;
	padding: 0;
	margin: 0;
}


.upgotd_page {
	.pray-for {
		text-align: center;
		padding: 10px 0 25px 0;
		font-size: 170%;
		font-weight: bold;
		color: @gray-darker;
		span {
			display: inline !important;
		}
		@media (max-width: @screen-md) {
			font-size: 150%;
		}
		@media (max-width: @screen-sm) {
			font-size: 125%;
		}
	}
	.table-upg-of-day {
		img  {
			max-width: 75px;
		}
		td {
			font-size: 90%;
			font-weight: bold;
			border: 1px solid @gray-dark;
			padding: 3px 5px;
		}
	}
	.upgotd_title {
		margin-bottom: 15px;
		@media (max-width: @screen-xs) {
			text-align: center;
			margin-bottom: 0px;
		}
		h2 {
			a, a:hover {
				color: @red2;
			}
			a:hover {
				text-decoration: underline;
			}
			margin-bottom: 10px;
			@media (min-width: @screen-lg) {
				margin-bottom: 30px;
			}
			@media (max-width: @screen-sm) {
				font-size: 150%;
			}
		}
		.date {
			float: right;
			padding: 8px 0px 0px 0px;
			font-size: 115%;
			font-weight: bold;
		}
		.upgotd_menu {
			text-align: center;
			margin: 0px auto;
			line-height: 225%;
			@media (min-width: @screen-lg) {
				margin: 25px auto 10px;
			background: @gray-lighter;
			padding: 10px 0 2px;
			}
		}
		.upgotd_tab_title {
			display: inline-block;
			font-size: 130%;
			font-weight: bold;
			margin: 0;
			@media (min-width: @screen-lg) {
				padding: 0 15px;
			}
			@media (max-width: @screen-lg) {
				padding: 0 10px;
				font-size: 115%;
			}
			@media (max-width: @screen-xs) {
				padding: 0 8px;
				font-size: 105%;
			}
			.glyphicon {
				margin-right: 5px;
				@media (max-width: @screen-sm) {
					margin-right: 3px;
				}
				@media (max-width: @screen-xs) {
					display: none;
				}
			}
		}
		.sub_title {
			font-size: 100%;
			font-family: @font-family-sans-serif;
			font-weight: normal;
			color: @gray-dark;
			@media (min-width:@screen-lg) {
				display: inline;
				padding: 0px;
				float: right;
			}
			@media (max-width:@screen-lg) {
				display: block;
				text-align: center;
				margin: 10px 0 0;
			}

			a {
				margin-top: -7px;
			}
			a:hover {
				text-decoration: underline;
			}
			.no-underline {
				text-decoration: none !important;
			}
		}
		.widget {
			font-size: 80%;
			background-color: @link-color;
			color: @white1;
			box-shadow: 1px 1px 1px  @gray-lighter;
			border-top: 2px solid @link-color;
			border-right: 2px solid @link-color;
			border-left: 2px solid @link-color;
			border-radius: 3px;
			margin-right: 5px;
			@media (max-width: @screen-xs) {
				display: none;
			}
		}
		img {
			width: 33px;
			height: 42px;
			display: inline;
			vertical-align: top;
			margin-top: -9%;
			@media (max-width: @screen-lg) {
				width: 28px;
				height: 36px;
				margin-left: 0;
			}
			@media (max-width: @screen-md) {
				width: 19px;
				height: 27px;
				margin-left: 0;
			}
			@media (max-width: @screen-sm) {
				display: none;
			}
		}

	} // end upgotd title

	.upgotd-podcast {
		@media (max-width: @screen-xs) {
			margin-top: 0px;
		}
		.sub-title {
			font-size: 100%;
		}
	}

	.upgotd-widget {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.btn {
		margin: 0;
		padding: 5px 10px !important;
		font-size: 125%;
		color: white;
		font-family: 'Open Sans', sans-serif;
		@media screen and (max-width: @screen-xs-max) {
			font-size: 100%;
		}
	}

	.upgotd_top {
		margin: 0;
		.pic {
			box-shadow: 5px 2px 5px @gray-lighter;
			width: 260%;
			margin-top: 3%;
			@media (max-width: @screen-md) {
				width: 190px;
			}
			@media (max-width: @screen-sm) {
				width: 150px;
				margin-bottom: 4px;
			}
		}
		.stats {
			h2 {
				font-style: normal;
			}
		}
	}

	.side {
		background-color: @white1;
		padding-bottom: 1.7%;
	}

	.cal_col {
		width: 14.28%;
		font-size: 108%;
		text-align: center;
		 @media (max-width:@screen-lg) {
			font-size: 100%;
		 }
		 @media (max-width:@screen-md) {
			 font-size: 80%;
		 }
		 @media (max-width:@screen-sm) {
			font-size: 70%;
		 }
		 @media (max-width:@screen-xs) {
			font-size: 90%;
		 }
	}

	.cal_img {
		width: 85%;
		@media (max-width:@screen-sm) {
			width: 40%;
		}

		@media (max-width:@screen-xs) {
			display: none;
		}

	}
	.cal_td {
		font-size: 85%;
		text-align: center;
		border: solid 1px @gray-lighter;

	}
	.cal_td:hover {
		opacity: .85;

	}
	.day_num {
		font-weight: bold;
		display: inline-block;
	}
	.g_name {
		@media (max-width:@screen-xs) {
			display: none;
		}
	}
	table {
		/* width: 100% !important; */
		margin-left: 4% !important;
		margin-right: 4% !important;
		@media (max-width:@screen-sm) {
		   width: 100% !important;
		   margin-left: 0 !important;
		   margin-right: 0 !important;
		}
	}

	.btn-default {
		background-color: @gray-light;
		font-style: italic;
	}
	.btn-default:hover, .btn-default:active, .btn.active  {
		background-color: @red2;
		font-style: normal;
	}

	.btn-xs {
		background-color: transparent;
		@media (max-width: @screen-sm) {
			padding: 0;
			margin: 0 1px;
		}
		img {
			max-width: 40px;
			@media (max-width: @screen-sm) {
				max-width: 35px;
			}
		}
	}
	.btn-xs:hover, .btn-xs:active, .btn-xs:focus {
		opacity: .85;
		outline: none;
	}
	.hide {
		visibility: collapse;
	}

	.subscribe {
		margin-left: 8%;
		h3 {
			margin-left: 0;
		}

		p {
			font-size: 115%;
			line-height: 185%;
			margin: 0 0 10px 0;
			@media (max-width:@screen-md) {
			 	margin-right: 0;
			}
			@media (max-width:@screen-xs) {
				font-size: 95%
			}
		}
		@media (max-width:@screen-md) {
			margin-left: 0;
		}
		.contact_form {
			margin-left: 0;
		}
		.days-btn {
			width: 100px;
			padding: 3px;
			margin-left: 0;
		}
		btn-md {
			background-color: @gray-light !important;
		}
		btn-md:active {
			background-color: @red2 !important;
		}
	}

	.side {
		text-align: center;
	}

	.pray_section {
		width: 100%;
		margin-bottom: 15px;
		float: clear;
	}
	.pray_reading {
		display: inline-block;
		img {
			margin: 0 15px;
			@media (max-width:@screen-md) {
				margin: 0 5px 10px;
			}
		}
	}
	.profile-text {
		h4 {
			margin-bottom: 3px;
			padding-bottom: 0px;
		}
		.sub-title {
			font-size: 115%;
			padding: 5px 0 10px;
		}
	}
	.btn-lg {
		padding: 4px;
		color: @red2 !important;
		font-size: 125% !important;
		font-weight: 900;
		background-color: lighten(@gray-lighter, 10%) !important;
		display: inline-block;
		margin-left: 1%;
		border: solid 1px @red2;
		border-radius: 5px;
		margin-top: 1%;
		@media (max-width: @screen-sm) {
			font-size: 98% !important;
			margin-bottom: 10%;
			margin-top: 2%;
			margin-left: 0;
		}
	}

	.btn-lg:hover, .btn-lg:active {
		background-color: @red2 !important;
		color: white !important;
	}

	#IBNSubscribeForm {
		.form-control {
			color: #000;
		}
	}

	.popover {
		font-family: 'Open Sans', sans-serif;
		line-height: 135%;
		width: 585px;
		max-width: 585px;
		position: absolute;
		top: 0;
		left: 0;
		right: 10px;
		z-index: 625;
		display: none;
		h3 {
			color: @black1;
			font-size: 100%;
			margin: 2px 0 0 0;
		}
		.popover-title {
			background-color: transparent;
			display: block;
		}
		@media (max-width: @screen-lg) {
			font-size: 90%;
			width: 350px;
			max-width: 350px;
		}
		@media (max-width: @screen-md) {
			font-size: 110%;
			width: 300px;
			max-width: 300px;
		}
		@media (max-width: @screen-sm) {
			font-size: 100%;
			width: 200px;
			max-width: 2000px;
		}
	}

	.popover-content {
		color: @gray-darker;
		background-color: @offwhite2;
		font-size: 80%;
		display: block;
		font-weight: 200;
	}

	.upgotd_widget {
		margin: 0 5%;
		textarea {
			text-align: center;
			color: @black1;
		}
	}

	.upgotd-flags img {
		padding: 0px;
		margin: 0 5px;
	}

	form {
		/* max-width: 400px; */
		text-align: left;
		input, textarea {
			background-color: lighten(@gray-lighter, 5%);
			color: @gray-darker;
		}
		.btn {
			background-color: @red2;
			margin-top: 10px;
		}
	}
	#mc_embed_signup {
		h3 {
			display: inline;
			color:@red2;
			font-size: 140%;
			@media (max-width: @screen-xs) {
				font-size: 130%;
			}
		}
		form {
			width: 100%;
			@media (max-width: @screen-xs) {
				padding: 0;
			}
		}
		#mc_embed_header {
			max-width: 400px;
		}
		div.mc-field-group {
			padding-bottom: 15px;
		}
		.indicates-required {
			display: inline;
			float: right;
			font-size: 90%;
		}
		.asterisk {
			color: red;
			position: relative !important;
			font-size: 150%;
			top: 3px !important;
			right: 0 !important;
		}
		.mc-list-title {
			color: @red2;
			font-weight: bold;
			font-size: 125%;
			@media (max-width: @screen-xs) {
				font-size: 110%;
			}
			img {
				height: 27px;
				width: 40px;
				/* border-radius: 3px; */
				@media (max-width: @screen-xs) {
					height: 20px;
					width: 30px;
				}
			}
		}
		mc-field-group-spacer-1 {
			height: 35px;
			@media (min-width: @screen-sm) {
				display: hidden;
			}
		}
		div.mc-field-group.input-group input {
			margin: 0 7px 3px 15px;
			@media (max-width: @screen-xs) {
				margin-left: 25px;
			}
		}
		#mc-embedded-subscribe {
			line-height: 24px;
			background-color:@red2;
			color:@white1;
			&:hover {
				background-color:@gray;
			}
		}
		#mce-success-response {
			font-size: 125%;
		}

		#mce-responses {
			text-align: center;
			width: 100%;
		}
		#mce-error-response {
			margin-left: 24%;
			@media (max-width: @screen-lg) {
				margin-left: 15% !important;
			}
			@media (max-width: @screen-md) {
				margin-left: 17% !important;
			}
			@media (max-width: @screen-sm) {
				margin-left: 12% !important;
			}
			@media (max-width: @screen-xs) {
				margin-left: 2% !important;
			}
			max-width: 400px;
		}
		.center {
			text-align: center;
			margin-right: 12%;
			@media (max-width: @screen-xs) {
				margin-right: 0;
			}
			@media (max-width: @screen-sm) {
				margin-right: 3%;
			}
			@media (max-width: @screen-md) {
				margin-right: 6%;
			}
			margin-bottom: 20px;
		}
	}

	.table-scale-modal {
		font-size: 85%;
		@media (max-width:@screen-md) {
			font-size: 90%;
		}
		@media (max-width:@screen-sm) {
			font-size: 100%;
		}
		line-height: 150%;
		margin: 15px 0;
		th {
			background-color: @gray-light;
		}
		th, td {
			border: 1px solid black;
			padding: 5px;
			text-align: left;
			vertical-align: middle;
		}
		th:first-child, td:first-child {
			text-align: center;
			@media (max-width:@screen-md) {
				width: 10%;
			}
			@media (min-width:@screen-lg) {
				width: 5%;
			}
			font-weight: bold;
			a {
				color: @black;
			}
		}
		td:nth-child(2) {
			width: 53%;
		}
		th:nth-child(3), td:nth-child(3) {
			width: 42%;
		}
	}
	#praying-widget {
		max-width: 300px;
		margin: 0 auto;
		.btn-text {
			font-size: .9em !important;
			@media (max-width:@screen-md) {
				font-size: .7em !important;
			}
		}
	}
	.dropdown-labels {
		font-size: 1.2em;
		font-weight: bold;
		padding: 10px 0px 0px;
	}

	.upgotd-video {
		width: 600px;
		@media (max-width:@screen-xs) {
			max-width: 100%;
			max-height: 190px;
		}

	}

	/* For debugging widths */
	/*
	background-color: transparent;
	@media (max-width:@screen-xlg) {
		background-color: blue;
	}
	@media (max-width:@screen-lg) {
		background-color: red;
	}
	@media (max-width:@screen-md) {
		background-color: yellow;
	}
	@media (max-width:@screen-sm) {
		background-color: orange;
	}
	@media (max-width:@screen-xs) {
		background-color: green;
	}
	*/

}

//end upgotd

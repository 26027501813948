//
// Lightbox
// --------------------------------------------------

#lightbox .modal-dialog {
	padding: 30px 10px 0px 0px !important;
}

#lightbox .modal-body {
	padding: 5px 8px;
}

#lightbox .modal-content {
	background-color: none;
	display: inline-block;
	text-align: center; 
}

#lightbox .close {
	opacity: 1;
	line-height: 90%;
	font-size: 175%;
	color: rgb(255, 255, 255);
	background-color: rgb(25, 25, 25);
	border-radius: 25px;
	height: 30px;
	width: 30px;
	border: 2px solid rgb(255, 255, 255);
	position: absolute;
	top: 14px;
	right: -33px;
	z-index: 1032;
}